import feathersClient from "../services/feathersClient";
import moment from "moment";
// import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf.js";
const axios = require("axios");

const state = {
  exampleSet: null,
};

const mutations = {
  SET_EXAMPLESET(state, example) {
    state.exampleSet = example;
  },
};

const actions = {
  async createExample(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const { user } = context.rootState.user;
    const teamResourceService = feathersClient.service("teamResources");
    const tokenString = `Bearer ${accessToken}`;
    try {
      const userData = {
        accountId: user.accountId,
        organizationId: user.organizationId,
        type: "previous-winner-submissions",
        metaData: {
          accountUserId: user.aspenifyUserId,
          attachment: payload,
          date: moment().format("MMM-DD-YYYY"),
        },
        lastUpdatedBy: user.aspenifyUserId,
        createdBy: user.aspenifyUserId,
        suborganizationId: null,
      };
      await teamResourceService.create(userData, {
        headers: {
          Authorization: tokenString,
        },
      });
      await context.dispatch("getExamples");
    } catch (error) {
      console.log(error);
    }
  },
  async getExamples(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const { user } = context.rootState.user;
    const teamResourceService = feathersClient.service("teamResources");
    const tokenString = `Bearer ${accessToken}`;
    const currentPage = payload?.currentPage || 1;
    const numberOfRecordsToSkip = (currentPage - 1) * process.env.VUE_APP_LIST_PAGE_LIMIT
    try {
      let data = await teamResourceService.find({
        query: {
          accountId: user.accountId,
          organizationId: user.organizationId,
          type: "previous-winner-submissions",
          $limit: process.env.VUE_APP_LIST_PAGE_LIMIT,
          $skip: numberOfRecordsToSkip,
          $sort: {
            createdAt: -1,
          },
        },
        headers: {
          Authorization: tokenString,
        },
      });
      const totalRecords = data.total;
      const currentPage = payload?.currentPage || 1;
      const totalPages = data.total % Number(process.env.VUE_APP_LIST_PAGE_LIMIT) == 0 ?
      parseInt(data.total / Number(process.env.VUE_APP_LIST_PAGE_LIMIT)) :
      parseInt(data.total / Number(process.env.VUE_APP_LIST_PAGE_LIMIT)) + 1;
      context.commit('SET_PAGINATION_DATA', {
        currentPage: currentPage,
        totalPages,
        totalRecords
      });
      context.commit("SET_EXAMPLESET", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async viewExamplePdf(context, payload) {
    try {
      payload.isPublic = true;
      payload.accountId = context.rootState.user.user.accountId;

      let documentName = payload.documentName;
      let fileExtension = documentName
        .split(".")
        .pop()
        .toLowerCase();
      let fileName = payload.documentId + "." + fileExtension;
      let query = {
        fileName: documentName,
      };
      if (payload.isPublic) {
        query.accountId = "public";
        query.isPublic = true;
      }
      const downloadFileService = feathersClient.service('downloads');
      let fileData = await downloadFileService.get(fileName, {
        query: query
      });
      let a = document.createElement('a');
      a.href = fileData.uri.href;
      a.download = documentName;
      a.click();
    } catch (e) {
      console.warn("Error while downloading attachment -: " + e);
      throw e;
    }
  },
 async deleteExample(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const teamResourceService = feathersClient.service("teamResources");
    const tokenString = `Bearer ${accessToken}`;
    try {
      await teamResourceService.remove(payload, {
        headers: {
          Authorization: tokenString,
        },
      });
      context.dispatch("getExamples");
    } catch (error) {
      console.log(error);
    }
  }
};

const getters = {
  exampleSet: (state) => state.exampleSet,
};

const ExampleModule = {
  state,
  mutations,
  actions,
  getters,
};

export default ExampleModule;
