<template>
  <!-- <pre> {{ $route.params.userName }} </pre> -->
  <!--<pre>{{ setUserMetaData }}</pre> -->
  <div :key="componentkey">
    <loader-view
      v-if="loading"
      :loaderFlag="loading"
      :loadingTitle="`loading ${$route.params.userName} profile ...`"
    >
    </loader-view>

    <div class="p-6 text-sm text-gray-600" v-else-if="setSelectedUser">
      <div class="lg:flex md:block gap-4">
        <div class="lg:w-3/12 md:w-12/12">
          <div class="bg-white rounded mb-3">
            <h3 class="border-b px-3 py-2 font-semibold text-blue-900">
              Profile
            </h3>
            <div class="px-3 py-7">
              <div class="relative">
                <div
                  class="
                    shadow-lg
                    mx-auto
                    h-24
                    w-24
                    rounded-full
                    overflow-hidden
                    ring-4 ring-gray-200
                  "
                  v-if="selectedUser?.profilePic || profilePic"
                >
                  <img
                    class="object-cover w-full h-full"
                    :src="profilePic ? profilePic : selectedUser?.profilePic"
                  />
                </div>
                <div
                  class="
                    item-center
                    text-center
                    bg-gray-400
                    place-items-center
                    grid
                    font-thin
                    text-5xl text-white
                    shadow
                    mx-auto
                    h-24
                    w-24
                    border-white
                    rounded-full
                    overflow-hidden
                    ring-4 ring-gray-200
                  "
                  v-else
                >
                  {{ selectedUser?.firstName.charAt(0).toUpperCase()
                  }}{{ selectedUser?.lastName.charAt(0).toUpperCase() }}
                </div>
                <div class="text-center -mt-4" v-if="isEditAccess">
                  <label for="file-input" class="cursor-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="
                        h-6
                        w-6
                        inline-block
                        rounded-full
                        bg-white
                        p-1
                        border-3 border-black
                        shadow
                        hover:bg-gray-200
                      "
                      :class="{ 'animate-spin': uploadingImage }"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        v-if="uploadingImage"
                        fill-rule="evenodd"
                        d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                        clip-rule="evenodd"
                      />
                      <path
                        v-else
                        fill-rule="evenodd"
                        d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </label>

                  <input
                    id="file-input"
                    type="file"
                    class="hidden"
                    ref="file"
                    v-on:change="uploadingImage ? '' : onAddFiles()"
                    accept="image/x-png,image/jpeg,image/png"
                  />
                </div>
              </div>

              <div class="">
                <h1 class="text-lg text-center font-semibold mt-1">
                  {{ selectedUser?.firstName }} {{ selectedUser?.lastName }}
                </h1>
                <!-- <pre>{{user}}</pre> -->
                <p class="text-sm text-gray-600 text-center -mt-1">
                  {{ isContractor ? "Contractor" : "Employee" }}
                </p>
                <p class="text-sm text-gray-400 text-center">
                  {{ selectedUser?.email }}
                </p>
              </div>
            </div>
          </div>
          <div class="bg-white rounded mb-3">
            <h3 class="border-b px-3 py-2 font-semibold text-blue-900">
              About
            </h3>

            <div class="p-4">
              <div>
                <div v-if="editField === 'about'">
                  <inline-edit
                    len="2000"
                    component="textarea"
                    :value="userFormData?.aboutMe"
                    @editMe="editMe"
                    label="aboutMe"
                    @updateValue="updateValue"
                  >
                  </inline-edit>
                </div>
                <div
                  v-else
                  @click="isEditAccess ? editMe('about') : null"
                  :class="isEditAccess ? hoverClass : null"
                  class="mt-2 text-base text-gray-600 py-1"
                >
                  <rich-text
                    v-if="userFormData?.aboutMe"
                    :text="userFormData?.aboutMe"
                  ></rich-text>
                  <div v-else>NA</div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white rounded mb-3">
            <h3 class="border-b px-3 py-2 font-semibold text-blue-900">
              Expertise

              <div
                class="
                  w-5
                  h-5
                  float-right
                  cursor-pointer
                  hover:opacity-60
                  hidden
                  group-hover:block
                "
              ></div>
            </h3>

            <div class="p-4">
              <div v-if="editField === 'tagging'">
                <div class="w-full">
                  <inline-edit
                    component="tagging"
                    isRequired="false"
                    :value="userFormData?.interest || []"
                    @editMe="editMe"
                    label="interest"
                    @updateValue="updateValue"
                    :options="userFormData?.interest"
                  >
                  </inline-edit>
                </div>
              </div>

              <div
                class="flex py-2 upppercase text-gray-700 rounded"
                v-else
                :class="isEditAccess ? hoverClassDropdown : null"
                @click="isEditAccess ? editMe('tagging') : null"
              >
                <span v-if="userFormData?.interest?.length !== 0">
                  <span
                    v-for="tag in userFormData?.interest"
                    :key="tag"
                    class="
                      m-0.5
                      inline-block
                      px-2
                      py-1
                      leading-none
                      border border-blue-500
                      text-blue-500
                      rounded-full
                      font-semibold
                      tracking-wide
                      text-xs
                    "
                    >{{ tag }}
                  </span>
                </span>
                <span v-else>NA</span>
                <br />
              </div>
            </div>
          </div>
        </div>

        <div v-if="!isJudgeOrUser || isEditAccess" class="lg:w-9/12 sm:w-12/12">

          <div class="bg-white rounded mb-3">
            <h3 class="border-b px-3 py-2 font-semibold text-blue-900">
              {{ setName }} Submissions
              <span
                style="vertical-align: text-bottom"
                class="
                  rounded-full
                  inline-block
                  bg-blue-800
                  
                  h-5
                  text-center text-white text-xs
                  ml-1
                  pt-0.5
                  px-2
                "
              >
                {{ ideaList?.length }}</span
              >
              <div
                class="w-5 h-5 float-right cursor-pointer hover:opacity-60"
                @click="clickSortIdea(ideaList)"
              >
                <svg
                  v-if="!sortIdea"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 "
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z"
                  />
                </svg>

                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z"
                  />
                </svg>
              </div>
            </h3>

            <div class="overflow-y-auto" v-if="ideaList?.length !== 0">
              <div
                v-for="(idea, index) in ideaList"
                :key="{ index }"
                class="
                  flex
                  w-full
                  items-center
                  py-2
                  relative
                  hover:bg-gray-50
                  px-3
                  border-b
                  transition-all
                  duration-200
                "
                v-bind:style="{cursor:!isJudgeOrUser || isEditAccess?`pointer`:'default'}"
                @click="redirectToIdea(idea)"
              >
                <div class="w-20 flex flex-col items-center">
                  <div
                    class="
                      flex
                      relative
                      bg-orange-500
                      justify-center
                      items-center
                    "
                  >
                    <img alt="A" :src="idea.picture" />
                  </div>
                </div>
                <div class="w-full items-center flex-1">
                  <div class="ml-2 -mt-1 font-semibold">
                    {{ idea.name }}
                    <div
                      class="
                        text-xs
                        truncate
                        w-full
                        normal-case
                        font-normal
                        text-gray-500
                      "
                    >
                      {{ idea?.role?.join(" | ") }}
                    </div>
                    <div
                      class="
                        text-xs
                        truncate
                        w-full
                        normal-case
                        font-light
                        text-gray-500
                      "
                    >
                      {{ idea?.metaData?.teamMembers?.length }} Participant

                      <span class="float-right">{{
                        formatDate(idea.createdAt)
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="p-2 font-semibold">No Submissions</div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <!-- This is an example component -->
      <div class="h-screen bg-gray-100 flex items-center">
        <div
          class="
            container
            flex flex-col
            md:flex-row
            items-center
            justify-center
            px-5
            text-gray-700
          "
        >
          <div class="max-w-md">
            <div class="text-5xl font-dark font-bold">404</div>
            <p class="text-2xl md:text-3xl font-light leading-normal">
              Sorry we couldn't find this page.
            </p>
            <p class="mb-8">
              But dont worry, you can find plenty of other things on our
              homepage.
            </p>

            <button
              class="
                px-4
                inline
                py-2
                text-sm
                font-medium
                leading-5
                shadow
                text-white
                transition-colors
                duration-150
                border border-transparent
                rounded-lg
                focus:outline-none focus:shadow-outline-blue
                bg-blue-600
                active:bg-blue-600
                hover:bg-blue-700
              "
              @click="$router.push({ name: 'SubmissionLibrary' })"
            >
              back to homepage
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createLogger, mapGetters } from "vuex";
import { format } from "fecha";
import Pie from "../components/Charts/Pie.vue";
import InlineEdit from "../components/InlineEdit.vue";
import IconPencil from "../components/Icon/Icon-Pencil.vue";
import RichText from "../components/RichText.vue";
import _ from "lodash";
import LoaderView from "../components/progressView/Activities/LoaderView.vue";
import feathersClient from "../services/feathersClient";
export default {
  components: {
    Pie,
    IconPencil,
    InlineEdit,
    RichText,
    LoaderView,
  },

  data() {
    return {
      componentKey: 0,
      activeItem: "tab1",
      profilePic: "",
      editField: "",
      phaseData: [],
      allInitiative: [],
      sortIdea: false,
      uploadingImage: false,
      userFormData: {
        aboutMe: "",
        interest: [],
      },
      allIdeas: [],
      ideaList: [],
      activeTab: 1,
      displayIdeas: [],
      allLikes: [],
      allFollows: [],
      userId: this.$route.params.userId,
      pageUpdated: false,
      loading: false,
      isContractor: false,
    };
  },
  created: async function () {
    this.loading = true;
    let payload = {
      params: this.$route.params,
      aspenifyUserId: this.$route.params.userId,
    };
    await this.$store.dispatch("getUserDetails", payload);
    const accountUserMetadataServiceService = await feathersClient.service(
      "accountusermetadata"
    );
    let userMetaData = await accountUserMetadataServiceService.find({
      query: { accountUserId: this.$route.params.userId },
    });
    if (
      userMetaData?.data?.length > 0 &&
      userMetaData.data[0]?.metadata?.employment_type &&
      userMetaData.data[0].metadata.employment_type == "user.employee.vendor"
    ) {
      this.isContractor = true;
    }
    await this.isDataCorrect();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  mounted() {},
  beforeUpdate: function () {
    if (this.$route.params.isOwnProfile)
      this.pageUpdated = this.$route.params.isOwnProfile;
  },
  name: "MyProfile",
  props: {},
  watch: {
    selectedUser() {
      this.componentKey += 1;
    },
    initiatives() {
      this.componentKey += 1;
    },
    userFormData() {
      this.componentKey += 1;
    },
    async pageUpdated() {
      let payload = {
        params: this.$route.params,
        aspenifyUserId: this.$route.params.userId,
      };
      this.$store.dispatch("getUserDetails", payload);
      const accountUserMetadataServiceService = await feathersClient.service(
        "accountusermetadata"
      );
      let userMetaData = await accountUserMetadataServiceService.find({
        query: { accountUserId: this.$route.params.userId },
      });
      if (
        userMetaData?.data?.length > 0 &&
        userMetaData.data[0]?.metadata?.employment_type &&
        userMetaData.data[0].metadata.employment_type == "user.employee.vendor"
      ) {
        this.isContractor = true;
      }
      this.isDataCorrect();
    },
  },
  mounted: function () {},

  computed: {
    ...mapGetters(["user", "selectedUser", "userMetaData"]),

    setSelectedUser() {
      return this.$store.getters.selectedUser;
    },
    setUserMetaData() {
      return this.$store.getters.userMetaData;
    },
    isEditAccess() {
      return this.setSelectedUser?._id === this.user?.aspenifyUserId;
    },
    hoverClass() {
      return "hover:bg-gray-200 hover:shadow-md rounded -mx-2 px-2";
    },
    hoverClassDropdown() {
      return "hover:bg-gray-200 hover:shadow-md rounded -mx-2 px-2";
    },
    setName() {
      if (this.activeTab === 1) {
        return "Team";
      } else if (this.activeTab === 2) {
        return "Mentor";
      } else if (this.activeTab === 3) {
        return "Liked";
      } else if (this.activeTab === 4) {
        return "Following";
      }
    },
    userAccess() {
      return this.user && this.user.roles.length===1 && this.user.roles.includes("user");
    },
    isJudgeOrUser() {
      return (this.user && this.user.roles.length===1 &&  (this.user.roles.includes("judgeManager") || this.user.roles.includes("judgeMember") || this.user.roles.includes("user")))
    },
    Team() {
      let tM = _.filter(this.displayIdeas, {
        metaData: {
          teamMembers: [
            {
              email: this.selectedUser?.email,
            },
          ],
        },
      });
      let tL = _.filter(this.displayIdeas, {
        metaData: {
          teamLead: [
            {
              email: this.selectedUser?.email,
            },
          ],
        },
      });
      //return [...tM];
      //return [...tL];
      return [...tM, ...tL];
    },
    Mentor() {
      let idea = _.filter(this.displayIdeas, {
        metaData: {
          mentors: [
            {
              email: this.selectedUser?.email,
            },
          ],
        },
      });
      return idea;
    },
  },
  methods: {
    async isDataCorrect() {
      if (this.setSelectedUser) {
        let payload = {
          params: this.$route.params,
          aspenifyUserId: this.$route.params.userId,
        };
        await this.$store.dispatch("getUserMetaData", payload);
        let ud = this.$store.getters.userMetaData;
        this.userFormData.aboutMe = ud?.aboutMe || "";
        this.userFormData.interest = ud?.interest || [];
        this.displayIdeas = await this.$store.dispatch(
          "getInitiativeByUser",
          payload
        );
        this.allLikes = await this.$store.dispatch("getUsersLikes", payload);
        this.allFollows = await this.$store.dispatch(
          "getUsersFollows",
          payload
        );
        this.myIdeas(this.Team);
      }
    },
    formatDate(date) {
      if (date) {
        return format(new Date(date), "MMM D, YYYY");
      } else {
        return "NA";
      }
    },
    clickSortIdea(ideas) {
      this.sortIdea = !this.sortIdea;
      if (this.activeTab === 1) {
        this.myIdeas(ideas);
      } else if (this.activeTab === 2) {
        this.myIdeas(ideas);
      } else if (this.activeTab === 3) {
        this.myIdeas(ideas);
      } else if (this.activeTab === 4) {
        this.myIdeas(ideas);
      }
    },
    clickSubmitted() {
      this.activeTab = 1;
      this.myIdeas(this.Team);
    },
    clickParticipated() {
      this.activeTab = 2;
      this.myIdeas(this.Mentor);
    },
    clickLike() {
      this.activeTab = 3;
      this.myIdeas(this.allLikes);
    },
    clickFollow() {
      this.activeTab = 4;
      this.myIdeas(this.allFollows);
    },
    myIdeas(ideas) {
      if (this.sortIdea) {
        this.ideaList = _.sortBy(ideas, (o) => o.createdAt);
      } else {
        this.ideaList = _.sortBy(ideas, (o) => o.createdAt).reverse();
      }

      //  let idea = _.filter(this.displayIdeas, {
      //   metaData: { mentors : [{
      //     email : this.user?.email
      //   }] },
      // });

      // let idea = [];
      // ideas?.filter((f, i) => {
      //   let role = [];
      //   let members = [];
      //   if (f) {
      //     members = [
      //       ...f?.metaData?.teamMembers,
      //       ...f?.metaData?.mentors,
      //       ...f?.metaData?.teamLead,
      //     ];

      //     members?.filter((m) => {
      //       if (m.email === this.user?.email) {
      //         if (m.role) {
      //           role.push(m.role);
      //         }
      //       }
      //     });
      //   }

      //   idea.push({
      //     id: f._id,
      //     name: f.name,
      //     image: f.picture,
      //     createdAt: format(new Date(f.createdAt), "mediumDate"),
      //     role: role,
      //     participant: members.filter((p) => p.email !== this.user?.email),
      //   });
      // });
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    editMe(item) {
      this.editField = item;
    },
    redirectToIdea(idea) {
      if(this.isEditAccess) this.$router.push({ name: "MyIdeaDetails", params: { id: idea._id } });
      if(!this.isJudgeOrUser)
      this.$router.push({ name: "IdeaDetails", params: { id: idea._id } });
    },
    onAddFiles() {
      if (this.$refs.file.files[0].size < 4000000) {
        this.errorText = "";
        this.uploadingImage = true;
        this.uploadFileToCloudinary(this.$refs.file.files[0])
          .then((fileResponse) => {
            this.profilePic = fileResponse?.secure_url;
            this.uploadingImage = false;
            let payload = {
              user: this.user,
              id: this.user?.aspenifyUserId,
              email: this.user?.email?.toLowerCase(),
              profilePic: fileResponse?.secure_url,
            };
            this.$store.dispatch("updateProfileImage", payload);
          })
          .catch((err) => {
            this.uploadingImage = false;
          });
      } else {
        this.errorText = "Upload Image below than 4 MB";
      }
    },
    uploadFileToCloudinary(file) {
      return new Promise((resolve, reject) => {
        // const CLOUDINARY_URL =  "https://api.cloudinary.com/v1_1/dqj2q4qee/image/upload";
        // const CLOUDINARY_UPLOAD_PRESET = "xptgrebi";

        const CLOUDINARY_URL = process.env.VUE_APP_CLOUDINARY_URL;
        const CLOUDINARY_UPLOAD_PRESET = process.env.VUE_APP_CLOUDINARY_PRESET;

        let formData = new FormData();
        formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
        //formData.append("folder", "cloudinary-demo");
        formData.append("file", file);

        let request = new XMLHttpRequest();
        request.open("POST", CLOUDINARY_URL, true);
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");

        request.onreadystatechange = () => {
          // File uploaded successfully
          if (request.readyState === 4 && request.status === 200) {
            let response = JSON.parse(request.responseText);
            resolve(response);
          }

          // Not successfull, let find our what happened
          if (request.status !== 200) {
            let response = JSON.parse(request.responseText);
            let error = response.error.message;
            this.errorText = "error uploading files : " + error;
            this.isError = true;
            reject(error);
          }
        };

        request.onerror = (err) => {
          //  alert("error: " + err);
          reject(err);
        };

        request.send(formData);
      });
    },
    async updateValue(data) {
      let payload = {
        user: this.user,
        data: {
          key: data.label,
          label: "metadata." + data.label,
          value: data.value,
        },
      };
      await this.$store.dispatch("updateProfile", payload);
      this.userFormData[data.label] = data.value;
      this.editField = null;
    },
  },
};
</script>

<style scoped></style>


