<template>
  <transition name="modal">
    <div
      class="
        modal
        h-screen
        w-full
        fixed
        left-0
        top-0
        flex
        justify-center
        items-center
        bg-black bg-opacity-40
        g-opacity-50
        z-100
      "
    >
      <div class="bg-white shadow-lg w-7/12 text-gray-600">
        <div class="border-b p-4 bg-blue-900 flex justify-between items-center">
          <div class="font-medium text-white text-lg flex w-6/12">
            <div class="flex-grow w-1/12 font-bold mt-2">
              Configure Date {{ setName || setActionName ? "for" : "" }}
              {{ setName ? setName : setActionName }}
            </div>
          </div>
          <button
            class="
              bg-blue-400
              text-sm text-white
              py-2
              px-2
              rounded-full
              focus:outline-none
            "
            @click="$emit('closeConfigureDateModal')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>

        <div class="grid grid-cols-2 gap-2">
          <div class="p-5 bg-gray-200">
            <p class="font-bold">The Request has been submitted</p>
            <div class="items-center shadow-md mt-2 rounded bg-white">
              <div class="bg-white w-full flex items-center py-2">
                <img
                  :src="setData?.commentBy?.profilePic"
                  :title="setData?.commentBy?.name"
                  alt="avatar"
                  class="w-10 h-10 object-cover rounded-full mx-3"
                />
                <div class="w-full">
                  <div class="float-right px-3 py-3 text-sm">
                    {{ timeago.format(new Date(setData?.date)) }}
                  </div>
                  <a href="#" class="text-gray-700 font-bold hover:underline">
                    {{ setData?.commentBy?.name }}
                  </a>

                  <div class="text-gray-700 text-sm font-light">
                    {{ setData?.commentBy?.role }}
                  </div>
                </div>
              </div>
              <p class="p-2 border-t border-gray-100 h-60 overflow-auto">
                {{ setData?.comment }}
              </p>
            </div>
          </div>

          <div class="p-5">
            <p class="font-bold">
              Do you want to configure date
              {{ setName || setActionName ? "for" : "" }}
              {{ setName ? setName : setActionName }}?
            </p>
            <p class="text-sm">
              Configure date and time
              {{ setName || setActionName ? "for" : "" }}
              {{ setName ? setName : setActionName }}
            </p>
            <div class="py-5 grid grid-cols-1 gap-2">
              <!-- {{ date }} <br />{{ from }}<br />{{ to }} -->
              <!-- <div class="mb-2">
                <h2 class="font-semibold">Date</h2>
                <v-date-picker
                  v-model="date"
                  :model-config="modelConfig"
                  mode="date"
                  :min-date="new Date()"
                >
                  <template #default="{ inputValue, inputEvents }">
                    <div class="relative">
                      <svg
                        class="
                          text-gray-600
                          w-4
                          h-full
                          mx-2
                          absolute
                          pointer-events-none
                        "
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        ></path>
                      </svg>
                      <input
                        class="pl-8 pr-2 py-1 border rounded"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </div>
                  </template>
                </v-date-picker>
              </div> -->
              <div class="mb-2">
                <v-date-picker
                  mode="dateTime"
                  v-model="dateRange"
                  :model-config="modelConfig"
                  is-range
                  :min-date="nextDay"
                  :time-range="dateRange"
                />
              </div>
            </div>

            <div
              class="text-gray-700"
              :class="{
                'text-red-500': !statusLabelTextFlag,
              }"
            >
              {{ statusLabel }}
            </div>

            <div class="text-right mt-3">
              <button
                class="
                  inline-flex
                  text-gray-700
                  bg-gray-100
                  border-0
                  py-1
                  px-3
                  focus:outline-none
                  hover:bg-gray-200 hover:border hover:border-gray-700
                  rounded
                  text-base
                  mx-2
                "
                @click="this.$emit('closeConfigureDateModal')"
              >
                Cancel
              </button>

              <button
                :disabled="minuteDifferenceBetweenTime"
                class="
                  inline-flex
                  text-white
                  bg-indigo-500
                  border-0
                  py-1
                  px-3
                  focus:outline-none
                  hover:bg-indigo-600
                  rounded
                  disabled:opacity-30
                "
                @click="
                  this.$emit(
                    'submitConfigureSchedule',
                    dateRange.start,
                    dateRange.start,
                    dateRange.end
                  )
                "
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import * as timeago from "timeago.js";
export default {
  name: "ConfigureDateForOpenmic",
  components: {},
  props: {
    actionType: String,
    selectedInitiative: Object,
    configureOrScheduleEvent: String,
  },
  emits: ["closeConfigureDateModal"],
  data(props) {
    const start =
      this.configureOrScheduleEvent === "rescheduleOpenMic"
        ? new Date(
            this.selectedInitiative?.metaData?.signUpForOpenmic[
              this.selectedInitiative?.metaData?.signUpForOpenmic?.length - 1
            ]?.configure?.scheduledOn?.from
          )
        : this.configureOrScheduleEvent === "rescheduleArchitecture"
        ? new Date(
            this.selectedInitiative?.metaData?.registerArchitecture[
              this.selectedInitiative?.metaData?.registerArchitecture?.length -
                1
            ]?.configure?.scheduledOn?.from
          )
        : this.configureOrScheduleEvent === "rescheduleBuildingBlock"
        ? new Date(
            this.selectedInitiative?.metaData?.registerBuildingBlock[
              this.selectedInitiative?.metaData?.registerBuildingBlock?.length -
                1
            ]?.configure?.scheduledOn?.from
          )
        : this.configureOrScheduleEvent === "rescheduleStaff"
        ? new Date(
            this.selectedInitiative?.metaData?.registerExtendedStaff[
              this.selectedInitiative?.metaData?.registerExtendedStaff?.length -
                1
            ]?.configure?.scheduledOn?.from
          )
        : new Date();

    // let endDate = new Date();
    // endDate.setMinutes(start.getMinutes() + 1);
    const end =
      this.configureOrScheduleEvent === "rescheduleOpenMic"
        ? new Date(
            this.selectedInitiative?.metaData?.signUpForOpenmic[
              this.selectedInitiative?.metaData?.signUpForOpenmic?.length - 1
            ]?.configure?.scheduledOn?.to
          )
        : this.configureOrScheduleEvent === "rescheduleArchitecture"
        ? new Date(
            this.selectedInitiative?.metaData?.registerArchitecture[
              this.selectedInitiative?.metaData?.registerArchitecture?.length -
                1
            ]?.configure?.scheduledOn?.to
          )
        : this.configureOrScheduleEvent === "rescheduleBuildingBlock"
        ? new Date(
            this.selectedInitiative?.metaData?.registerBuildingBlock[
              this.selectedInitiative?.metaData?.registerBuildingBlock?.length -
                1
            ]?.configure?.scheduledOn?.to
          )
        : this.configureOrScheduleEvent === "rescheduleStaff"
        ? new Date(
            this.selectedInitiative?.metaData?.registerExtendedStaff[
              this.selectedInitiative?.metaData?.registerExtendedStaff?.length -
                1
            ]?.configure?.scheduledOn?.to
          )
        : new Date();
    // from.setMinutes(0, 0, 0);
    // to.setMinutes(0, 0, 0);
    const eventTimeDuration = process.env.VUE_APP_EVENT_TIME_DURATION;
    let minutes = eventTimeDuration % 60;
    let hours = (eventTimeDuration - minutes) / 60;
    // let output = hours + ":" + minutes;
    return {
      timeago,
      dateRange: {
        start,
        end,
      },
      modelConfig: {
        // start: {
        //   timeAdjust: '00:00:00',
        // },
        // end: {
        //   timeAdjust: '23:59:59',
        // },
      },
      statusLabelText: `Duration ${hours}hr ${minutes}min`,
      statusLabelTextFlag: true,
    };
  },
  watch: {
  },
  methods: {},
  computed: {
    setActionType() {
      let data = [];
      let meta = this.selectedInitiative?.metaData;
      if (this.actionType === "configureSignUp") {
        data = meta?.signUpForOpenmic;
      } else if (this.actionType === "configureArchitecture") {
        data = meta?.registerArchitecture;
      } else if (this.actionType === "configureBuildingBlock") {
        data = meta?.registerBuildingBlock;
      } else if (this.actionType === "configureStaff") {
        data = meta?.registerExtendedStaff;
      }
      return data;
    },
    setData() {
      let user =
        this.setActionType[this.setActionType?.length - 1]?.userComment;
      return user;
    },
    setName(key) {
      switch (this.configureOrScheduleEvent) {
        case "rescheduleOpenMic":
          return "Open Mic";
          break;

        case "rescheduleArchitecture":
          return "Architecture Review";
          break;

        case "rescheduleBuildingBlock":
          return "Building Block Review";
          break;

        case "rescheduleStaff":
          return "Extended staff Review";
          break;

        default:
          return null;
          break;
      }
    },
    setActionName(key) {
      switch (this.actionType) {
        case "configureSignUp ":
          return "Open Mic";
          break;

        case "configureArchitecture":
          return "Architecture Review";
          break;

        case "configureBuildingBlock":
          return "Building Block Review";
          break;

        case "configureStaff":
          return "Extended Staff Review";
          break;

        default:
          return null;
          break;
      }
    },
    minuteDifferenceBetweenTime() {
      const eventTimeDuration = process.env.VUE_APP_EVENT_TIME_DURATION;
      let minutes = eventTimeDuration % 60;
      let hours = (eventTimeDuration - minutes) / 60;
      let difference =
        this.dateRange.end.getTime() - this.dateRange.start.getTime();
      let resultInMinutes = Math.round(difference / 60000);
      let currentDate = new Date();
      let minutesToRemove = 1;
      let futureDate = new Date(
        currentDate.getTime() - minutesToRemove * 60000
      );
      if (futureDate > this.dateRange.start.getTime()) {
        this.statusLabelText = "Start time must be greater than current time";
        this.statusLabelTextFlag = false;
        return true;
      } else if (resultInMinutes >= parseInt(eventTimeDuration)) {
        this.statusLabelText = `Duration ${hours}hr ${minutes}min`;
        this.statusLabelTextFlag = true;
        return false;
      } else {
        this.statusLabelText = `Time Duration must be ${hours}hr ${minutes}min`;
        this.statusLabelTextFlag = false;
        return true;
      }
      // return resultInMinutes;
    },
    nextDay() {
      let tomorrow = new Date();
      // tomorrow.setDate(tomorrow.getDate()+1);
      return tomorrow;
    },
    statusLabel() {
      return this.statusLabelText;
    },
  },
  mounted: function () {

  },
  created: function () {

  },
  beforeUpdate() {
    
  },
};
</script>
