<template >
  <div :key="componentKey" :id="item.id">
    <div v-if="layout === 'grid'">
      <div
        class="
          relative
          flex
          border border-transprant
          flex-col
          block
          overflow-hidden
          cursor-pointer
          z-10
        "
        :class="{
          'border-cisco-blue bg-blue-50 shadow-inner shadow-red-500 rounded':
            item.id === selectedIdea?._id,
        }"
        @click.prevent="selectIdea(item)"
      >
        <!-- :class="{
          'shadow-2xl border border-blue-700': item.id === selectedIdea?._id,
        }" -->

        <div class="absolute z-10 text-white text-xs antialiased tracking-wide">
          <!-- <div v-if="isJudgeOrUser && (item.status == 1 ||item.status == 6)" class="bg-green-500 px-3 py-1 rounded-tl rounded-br shadow">
            {{ "Submitted" }}
          </div> -->
          <div 
            class="px-3 py-1 rounded-tl rounded-br shadow"
            :class="{
              'bg-green-500': item.status === -1,
              'bg-yellow-500': item.status === 0,
              'bg-green-500': item.status === 1,
              'bg-blue-500': item.status === 2,
              'bg-gray-500': item.status === 3,
              'bg-red-500': item.status === 5,
              'bg-gray-600': item.status === 6,
              'bg-gray-500': item.status === 7,
            }"
          >
            {{ item.status === 2 ? "Promoted" : item.statusLabel }}
          </div>
        </div>
        <div
          class="
            absolute
            z-10
            text-white text-xs
            antialiased
            tracking-wide
            right-0
          "
          v-tooltip="{
            theme: {
              placement: 'bottom-left',
              theme: {},
            },
          }"
        >
          <div
            v-if="item?.parentInitiativeId?.metaData?.isTalentRequired"
            class="px-1 py-1 rounded-tr rounded-bl bg-green-600 shadow"
            v-tooltip="'Looking for Talent'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
        <div class="relative pb-48 overflow-hidden">
          <!-- <pre>{{item.image}}</pre> -->
          <img
            class="
              absolute
              inset-0
              h-full
              w-full
              object-cover
              rounded-tl rounded-tr
            "
            :src="item.image"
            alt=""
          />
          <div
            v-if="item.phase"
            class="
              absolute
              bottom-0
              right-0
              left-0
              block
              z-10
              bg-gray-500
              text-white
              px-3
              py-2
              text-xs
              antialiased
              tracking-wide
              h-10
            "
          >
            <div class="pt-1 inline-block font-bold">
              {{ item.phase }}
            </div>
          </div>
        </div>

        <div class="flex-1 p-4" style="min-height: 180px">
          <!-- <div class="block float-right text-right w-full">
            <status-track
              :teams="item.activities[0].activities"
              :initiative="item?.parentInitiativeId"
            />
          </div> -->
      <div>
        <div class="dropdown inline-block relative z-30">
          <span
            class="
              mr-1
              inline-block
              px-2
              py-1
              leading-none
              border border-blue-500
              text-blue-500
              rounded-full
              font-semibold
              text-xs
            "
            >{{ item?.parentInitiativeId?.metaData.challengeType }}</span
          >
        </div>
      </div>
          
          <div class="float-left w-full block">
            <h2
              class="
                mt-2
                mb-2
                text-gray-800
                font-bold
                break-words
                line-clamp-2
                h-10
              "
            >
              {{ item?.parentInitiativeId?.name }}
            </h2>
          </div>

          <div
            class="
              line-clamp-3
              text-gray-500
              break-words
              overflow-y-hidden
              float-left
              block
              w-full
            "
          >
            <!-- <RichText :text="item.description" /> -->
            <p class="space-y-0 html-text" v-html="item?.parentInitiativeId?.description"></p>
          </div>
        </div>

        <div class="p-4 border-t border-b text-xs text-gray-700">
          <div class="flex items-center justify-between leading-tight">
            <div class="text-grey-darker text-sm">
              <h3>Created At</h3>
              <p class="inline-block font-semibold">
                {{ item.createdAt }}
              </p>
            </div>
            <div class="text-grey-darker text-sm text-right">
              <h3>Edited At</h3>
              <p class="inline-block font-semibold">
                {{ item.updatedAt }}
              </p>
            </div>
          </div>
        </div>

        <div class="p-4 text-xs text-gray-700">
          <div class="flex items-center justify-between leading-tight">
            <avatar-grouping
              position="right"
              :dataObj="item?.parentInitiativeId?.metaData"
            />

            <div class="text-grey-darker text-sm">
              <!-- Like follow Share -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="layout === 'list'">
      <div class="w-full flex flex-col bg-content break-words">
        <div
          class="flex bg-white rounded relative h-32 border border-transprant"
          :class="{
            'border-cisco-blue bg-blue-50 shadow-inner shadow-red-500 rounded':
              item.id === selectedIdea?._id,
          }"
        >
        <!-- <div v-if="isJudgeOrUser && (item.status == 1 ||item.status == 6)" 
            class="
              absolute
              m-2
              z-10
              text-white
              px-3
              py-1
              text-xs
              antialiased
              rounded-full
              tracking-wide
              shadow-2xl
              bg-green-500
            "
        >
            {{ "Submitted" }}
          </div> -->
          <div 
            class="
              absolute
              z-10
              text-white
              px-3
              py-1
              text-xs
              antialiased
              rounded-tl
              rounded-br
              tracking-wide
              shadow-2xl
            "
            :class="{
              'bg-green-500': item.status === -1,
              'bg-yellow-500': item.status === 0,
              'bg-green-500': item.status === 1,
              'bg-blue-500': item.status === 2,
              'bg-gray-500': item.status === 3,
              'bg-red-500': item.status === 5,
              'bg-gray-600': item.status === 6,
              'bg-gray-500': item.status === 7,
            }"
          >
            {{ item.status === 2 ? "Promoted" : item.statusLabel }}
          </div>
          <img
            class="
              object-cover
              rounded rounded-tr-none rounded-br-none
              cursor-pointer
              w-2/12
              rounded
            "
            :src="item.image"
            alt=""
            @click.prevent="selectIdea(item)"
          />

          <div
            class="
              relative
              ml-4
              block
              pr-4
              cursor-pointer
              w-10/12
              overflow-auto
            "
            @click.prevent="selectIdea(item)"
          >
            <div class="text-black text-xl line-clamp-1 my-2">
              {{item?.parentInitiativeId?.name}}
            </div>
            <div class="text-gray-500 line-clamp-2" v-html="item.description">
            </div>
            <div
              class="absolute bottom-2 left-0 right-4 p-2 text-xs text-gray-700"
            >
              <div class="flex items-center justify-between leading-tight">
                <div class="flex -space-x-1 overflow-hidden">
                  <img
                    v-for="(user, index) in item.users.slice(0, 3)"
                    :key="index"
                    class="
                      inline-block
                      h-10
                      w-10
                      rounded-full
                      ring-2 ring-white
                    "
                    :src="user.image"
                    alt="users"
                    :title="user.name"
                    v-tooltip="{
                      text: user.name,
                      theme: {
                        placement: 'left',
                      },
                    }"
                  />

                  <div
                    v-if="item.users.length - 3 > 0"
                    class="
                      inline-block
                      rounded-full
                      w-10
                      h-10
                      bg-gray-500
                      p-0
                      border-0
                      inline-flex
                      items-center
                      justify-center
                      text-gray-500
                      ml-2
                    "
                  >
                    <div
                      class="
                        sm:text-base
                        text-base
                        font-medium
                        title-font
                        text-gray-200
                      "
                    >
                      +{{ item.users.length - 3 }}
                    </div>
                  </div>
                </div>
                <div class="text-grey-darker text-sm text-right float-right">
                  <div
                    v-if="item.phase"
                    class="
                      inline-block
                      px-2
                      py-1
                      mr-2
                      leading-none
                      bg-gray-500
                      text-white
                      rounded-full
                      font-semibold
                      tracking-wide
                      text-xs
                    "
                  >
                    {{ item.phase }}
                  </div>

                  {{ item.createdAt }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import statusTrack from "../statusTrack.vue";
import AvatarGrouping from "../AvatarGrouping.vue";
import ActionSignUpIcon from "../ActionSignUpIcon.vue";
export default {
  data() {
    return { showType: "", selectedId: this.selectedIdea?.id, componentKey: 0 };
  },
  components: { statusTrack, AvatarGrouping, ActionSignUpIcon },
  name: "IdeaCard",
  mounted: function () {},
  props: {
    item: Object,
    layout: String,
    selectedIdea: Object,
    isJudgeOrUser:Boolean
  },
  watch: {
    selectedIdea: function (newValue, oldValue) {
      this.componentKey += 1;
    },
  },
  created: async function () {},
  methods: {
    selectIdea(idea) {
      this.$emit("setParentIdea", idea);
      this.$emit("openPanel", true);
      this.$emit("selectedIdeaAction", idea);
      this.selectedId = idea.id;
      this.$router.push({ name: "Judging", hash: `#${idea.id}` });
    },
    showTypeOver: function (item) {
      this.showType = item;
    },
    showTypeOut: function (item) {
      this.showType = null;
    },
  },
  beforeUpdate() {},
};
</script>
<style scoped></style>
