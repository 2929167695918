<template>
  <transition name="modal">
    <div
      class="
        modal
        h-screen
        w-full
        fixed
        left-0
        top-0
        flex
        justify-center
        items-center
        bg-black
        g-opacity-50
      "
    >
      <div class="bg-white rounded shadow-lg w-10/12 md:w-2/4">
        <div
          class="
            border-b
            p-5
            bg-blue-900
            flex
            justify-between
            items-center
            rounded-t
          "
        >
          <h3 class="font-medium text-white text-lg">Change Exec Sponsor</h3>
          <button
            class="
              bg-blue-400
              hover:bg-blue-500
              text-sm text-white
              py-2
              px-2
              rounded-full
              focus:outline-none
            "
            @click="$emit('close')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>

        <div class="modal-body p-5 font-sans">
          <div class="flex justify-center z-20">
            <div class="w-1/2 mr-5">
              <label
                class="block text-gray-700 text-base font-bold mb-2"
                for="username"
                >Search Exec Sponsor</label
              >
              <autocompletesingleselect
                :suggestions="usersByDisplayName"
                :selection.sync="value1"
                :selected.sync="execSponsorForSelectedSubmission"
                :dropdownVisibleTo="dropdownVisibleTo"
                @displayNameFilterSearch="displayNameFilterSearch"
                @cleared="clearTeamLeadInput"
                accessTo="lead"
                @enableSave="enableSave"
                @setActiveWindow="setActiveWindow"
                openList="TeamLead"
                :initiative="initiative"
                :loaderFlag="loaderFlag"
              >
              </autocompletesingleselect>
            </div>

            <div class="w-1/2 ml-10">
              <label
                class="block text-gray-700 text-base font-bold mb-2"
                for="username"
                >Exec Sponsor</label
              >
              <div v-if="execSponsorForSelectedSubmission.length > 0">
                <div
                  class="
                    flex flex-col
                    bg-white
                    max-w-sm
                    px-6
                    py-2
                    mx-auto
                    rounded-lg
                    shadow-md
                  "
                >
                  <ul class="-mx-4">
                    <li class="" v-for="(user, index) in execSponsorForSelectedSubmission" :key="index">
                      <div class="bg-white w-full flex items-center rounded-xl">
                        <div class="relative items-center space-x-4">
                          <img
                            :src="user.image"
                            class="w-10 h-10 rounded-full"
                            :title="user.name"
                          />
                        </div>
                        <div class="flex-grow p-3 w-36">
                          <div class="font-semibold text-gray-700 break-words">
                            <span>{{ user.name }}</span>
                          </div>

                          <div class="text-gray-500 break-words flex">
                            <span> {{ user.email }}</span>
                          </div>
                        </div>
                        <div class="p-2">
                          <span
                            @click="removeExecSponsor(index)"
                            class="
                              cursor-pointer
                              block
                              h-4
                              w-4
                              ml-5
                              blue-400
                              fill-current
                              text-blue-600
                              bottom-0
                              right-0
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-trash-2"
                            >
                              <polyline points="3 6 5 6 21 6"></polyline>
                              <path
                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                              ></path>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-else>
                <info-card message="No Exec Sponsor selected!" />
              </div>
            </div>
          </div>
        </div>

        <div
          class="
            w-full
            text-right
            float-right
            items-center
            justify-between
            p-5
            bg-white
            border-t border-gray-200
            rounded-bl-lg rounded-br-lg
          "
        >
          <button
            type="button"
            @click="$emit('close')"
            class="
              mr-3
              inline-flex
              justify-center
              hover:scale-110
              focus:outline-none
              px-4
              py-2
              rounded
              cursor-pointer
              hover:bg-gray-200
              bg-gray-100
              text-gray-700
              duration-200
              ease-in-out
              transition
            "
          >
            Cancel
          </button>

          <button
            @click="updateExecSponsor"
            :disabled="!isEnable"
            class="
              inline-flex
              text-white
              bg-indigo-500
              border-0
              py-2
              px-4
              focus:outline-none
              hover:bg-indigo-600
              rounded
              text-md
              disabled:opacity-30
            "
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import users from "../services/users";
import mentors from "../services/mentors";
import { mapGetters } from "vuex";
import InfoCard from "./InfoCard.vue";

export default {
  name: "MyTeamManagementModal",
  props: {
    initiative: Object,
    msGraphAccessToken: String,
    proposer: Array,
  },
  components: {
    InfoCard,
  },
  data() {
    return {
      // users: users,
      // users: this.teamMembers,
      users: this.usersByDisplayName,
      mentors: mentors,
      value: "",
      value1: "",
      value2: "",
      isEnable: false,
      usersByDisplayName: [],
      dropdownVisibleTo: "",
      loaderFlag: false,

    };
  },
  created: function () {
  },
  computed: {
    ...mapGetters(["teamMembers", "execSponsorForSelectedSubmission", "user"]),
  },
  methods: {
    userSelected: function (selectedUser) {},
    deleteMentor: function (index) {
      this.initiative.mentors = this.initiative.mentors.filter(function (e, i) {
        return i != index;
      });
      this.isEnable = true;
    },
    deleteMember: function (index) {
      this.initiative.teamMembers = this.initiative.teamMembers.filter(
        function (e, i) {
          return i != index;
        }
      );
      this.isEnable = true;
    },
    removeExecSponsor: async function (index) {
      this.isEnable = false;
      await this.$store.dispatch("setExecSponsorForSelectedSubmission", []);
    },
    clearTeamMemberInput() {
      this.initiative.teamMembers = [...this.initiative.teamMembers];
    },
    clearMentorsInput() {
      this.initiative.mentors = [...this.initiative.mentors];
    },
    clearTeamLeadInput() {
      this.initiative.teamLead = [...this.initiative.teamLead];
    },
    enableSave() {
      this.isEnable = true;
    },
    setLoadeFlag(flag) {
      this.loaderFlag = flag;
    },
    async displayNameFilterSearch(value, selectedValues) {
      await this.setLoadeFlag(true);
      let payload = {
        value: value,
        accessToken: this.msGraphAccessToken,
      };
      let result = await this.$store.dispatch("getUsersByDisplayName", payload);
      // result = result.filter((e) => {
      //   return !this.initiative.users.some((item) => item.email?.toLowerCase() === e.email?.toLowerCase());
      // });
      if (selectedValues && selectedValues.length > 0) {
        let filteredResult = result.filter((e) => {
          return !selectedValues.some((item) => item.email?.toLowerCase() === e.email?.toLowerCase());
        });
        this.usersByDisplayName = filteredResult;
      } else {
        this.usersByDisplayName = result;
      }
      this.setLoadeFlag(false);
    },
    async setActiveWindow(value) {
      this.dropdownVisibleTo = value;
    },
    async updateExecSponsor(){
      if(this.execSponsorForSelectedSubmission?.length>0 && this.execSponsorForSelectedSubmission[0].role){
        delete this.execSponsorForSelectedSubmission[0].role;
      }
      let data = {
        id: this.initiative?.id,
        data: {
          key: "metaData.execSponsor",
          value: this.execSponsorForSelectedSubmission,
        }
      };
      this.$emit('close');
      let userObj = this.execSponsorForSelectedSubmission[0];
      let firstName = "";
      let lastName = "";
      let userName = "";
      let fullName = "";
      if(userObj.name.indexOf("(") > -1 && userObj.name.indexOf(")") > -1){
        let index = userObj.name.indexOf("(");
        fullName = userObj.name.slice(0, index);
      } else {
        fullName = userObj.name;
      }
      let index = fullName.indexOf(" ");
      firstName = fullName.slice(0, index).trim();
      lastName = fullName.slice(index).trim();
      userName = userObj.email.split("@")[0];
      userObj.firstName = firstName;
      userObj.lastName = lastName;
      userObj.accountId = this.user.accountId;
      userObj.organizationId = this.user.organizationId;
      let usersArray = [];
      usersArray.push(userObj);
      let addedUsers = await this.$store.dispatch("createUsers", usersArray);
      if(addedUsers.length>0){
        userObj.accountUserId = addedUsers[0]._id;
      }
      delete userObj.firstName;
      delete userObj.lastName;
      delete userObj.accountId;
      delete userObj.organizationId;
      await this.$store.dispatch("updateInitiativeById", data);
      await this.$store.dispatch("getInitiativeLogs", this.initiative?.id);
      await this.$store.dispatch("updateSelectedInitiativeExecSponsor", this.execSponsorForSelectedSubmission);
      //await this.$store.dispatch("getInitiativeById", this.initiative?.id);
    }
  },
};
</script>
<style scoped>
.modal {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  overflow-y: initial !important;
  position: fixed;
  z-index: 40;
  background-color: rgba(10, 10, 10, 0.86);
}

.modal-body {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>