<template>
  <div class="transition-all relative" style="display: inherit">
    <div>
      <div v-if="component == 'text'">
        <input
          autofocus
          class="
            bg-white
            appearance-none
            border border-gray-200
            rounded
            w-full
            py-2
            px-2
            text-gray-700
            leading-tight
            focus:outline-none focus:bg-white focus:border-gray-500
            mb-2
            -ml-2
          "
          id="title"
          v-model="value"
          :maxlength="len"
          type="text"
          @input="validName"
        />
      </div>
      <div v-if="component == 'currency'">
        <input
          autofocus
          class="
            bg-white
            appearance-none
            border border-gray-200
            rounded
            w-full
            py-2
            px-2
            text-gray-700
            leading-tight
            focus:outline-none focus:bg-white focus:border-gray-500
            mb-2
            -ml-2
          "
          id="title"
          v-model="newValue"
          type="text"
          @input="formatCurrency"
        />
      </div>
      <div v-else-if="component == 'textarea'">
        <!-- <QuillEditor
          theme="bubble"
          v-model:content="value"
          contentType="html"
        /> -->
        <JudgingCommentsTextEditor v-if="isJudgingComments"
            :content="value"
             @update-content="updateContent"
             :maxLimit = "len"
            @validName="validName"
            :label="this.label"
            />
        <AppTextEditor v-else
            :content="value"
             @update-content="updateContent"
             :maxLimit = "len"
             :minLimit="minLen"
            @validName="validName"
            :label="this.label"
            />
        <!-- <textarea
          autofocus
          rows="15"
          v-model="value"
          name="elevatorPitch"
          id="elevatorPitch"
          class="
            bg-white
            appearance-none
            border border-gray-200
            rounded
            w-full
            py-2
            px-2
            text-gray-700
            leading-tight
            focus:outline-none focus:bg-white focus:border-gray-500
            mb-2
          "
        /> -->
      </div>
      <div v-else-if="component == 'date'">
        <!-- <input
          autofocus
          class="
            bg-white
            appearance-none
            border border-gray-200
            rounded
            w-full
            py-2
            px-2
            text-gray-700
            leading-tight
            focus:outline-none focus:bg-white focus:border-gray-500
            mb-2
            -ml-2
          "
          id="title"
          v-model="value"
          type="date"
          @input="validName"
          :max="today"
        /> -->
        <v-date-picker v-model="value" @input="validName" :max-date="today" no-time>
              <template v-slot="{ inputValue, inputEvents }">
                <div class="flex relative border w-full border-gray-200 bg-white">
                  <div class="relative">
                    <svg
                      class="
                        text-gray-600
                        w-4
                        h-full
                        mx-2
                        absolute
                        pointer-events-none
                      "
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      ></path>
                    </svg>
                    <input
                    autofocus
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="MM/DD/YYYY"
                      class="
                        pl-8
                        pr-2
                        py-1
                        rounded
                        w-full
                        focus:outline-none
                        focus:bg-white focus:border-gray-500
                      "
                    />
                  </div>
                </div>
               </template>
              </v-date-picker>
      </div>
      <div v-else-if="component == 'number'">
        <input
          autofocus
          class="
            bg-white
            appearance-none
            border border-gray-200
            rounded
            w-full
            py-2
            px-2
            text-gray-700
            leading-tight
            focus:outline-none focus:bg-white focus:border-gray-500
            mb-2
            -ml-2
          "
          id="title"
          v-model="value"
          type="number"
          @input="validName"
          onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 187"
          :min="1"
        />
      </div>
      <div v-else-if="component == 'dropdown' && tags">
        <Multiselect
          class="
            block
            text-gray-700 text-base
            mb-2
            z-100
            text-sm
            multi-tag
            w-full
          "
          trackBy="label"
          label="label"
          v-model="value"
          :placeholder="setLabel"
          :options="optionsList"
        ></Multiselect>
      </div>
      <div v-else-if="component == 'dropdown'">
        <Multiselect
          class="
            block
            text-gray-700 text-base
            mb-2
            z-100
            text-sm
            multi-tag
            w-full
          "
          mode="tags"
          trackBy="label"
          label="label"
          v-model="value"
          placeholder="Select"
          :options="options"
        ></Multiselect>
      </div>

      <div v-else-if="component == 'singleSelect'">
        <Multiselect
          class="
            block
            text-gray-700 text-base
            mb-2
            z-100
            text-sm
            multi-tag
            w-full
          "
          mode="single"
          trackBy="label"
          label="label"
          v-model="value"
          placeholder="Select"
          :options="options"
        ></Multiselect>
      </div>

      <div v-else-if="component == 'tagging'">
        <Multiselect
          class="
            block
            text-gray-700 text-base
            font-normal
            mb-2
            multi-tag
            tagCustomInput
          "
          mode="tags"
          :createTag="true"
          :searchable="true"
          trackBy="label"
          label="label"
          v-model="value"
          :placeholder="'Type ' + updateText(label) + ' and Press Enter'"
          :options="options"
          @search-change="tagCheck"
        ></Multiselect>
      </div>
      <!-- <div v-else>NA</div> -->
    </div>
    <div class="block text-left float-inherit w-full mb-3 mt-1">
        <div v-if="label!='notes'" class="float-right ">
          <button
        class="
          justify-center
          text-sm
          font-medium
          hover:scale-110
          focus:outline-none
          py-1
          px-3
          rounded
          cursor-pointer
          hover:bg-gray-200
          bg-gray-100
          text-gray-700
          duration-200
          ease-in-out
          transition
          
        "
        type="button"
        @click="$emit('editMe')"
      >
        &#10539;
      </button>
      <button
        class="
          text-white
          bg-indigo-500
          border-0
          py-1
          px-3
          focus:outline-none
          hover:bg-indigo-600
          rounded
          text-sm
          disabled:opacity-30
          ml-2
        "
        :disabled="isDisabled"
        type="button"
        @click="$emit('updateValue', { label:this.label, value:this.contentValue})"
      >
        &#x2713;
      </button>
        </div>

      <span v-if="validationObject">
        <div
          class="text-sm space-y-0 html-text font-normal"
          :class="validationObject.textColor"
          v-html="validationObject.message"
        ></div>
      </span>
      <div
        class="text-sm font-normal text-red-500"
        v-if="tagAvaliable"
      >
        {{ updateText(label) }} already exists
      </div>
    </div>
  </div>
  <!-- <pre>{{options}}</pre> -->
</template>

<script>
import Multiselect from "@vueform/multiselect";
import { mapGetters } from "vuex";
import feathersClient from "../services/feathersClient";
import _ from "lodash";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "@vueup/vue-quill/dist/vue-quill.bubble.css";
import { object } from "yup/lib/locale";
import slugify from "../helpers/slugify"
import AppTextEditor from "../components/AppTextEditor.vue"
import JudgingCommentsTextEditor from "../components/JudgingCommentsTextEditor.vue"
import { format } from "fecha";
export default {
  name: "InlineEdit",
  data() {
    return {
      isDisabld: null,
      setLimit: null,
      selectLabel: "",
      alreadyExist: false,
      tagAvaliable: false,
      talentAvailable: false,
      contentValue:"",
      newValue:'',
      today:''
    };
  },
  components: {
    Multiselect,
    QuillEditor,
    AppTextEditor,
    JudgingCommentsTextEditor
  },
  props: {
    label: String,
    component: String,
    options: Array,
    value: [String, Array],
    tags: Boolean,
    len: Number,
    minLen:Number,
    isRequired: Boolean,
    className: String,
    index:Number,
    noteID:String,
    isJudgingComments:Boolean
  },
  mounted: function(){
      this.updatedValue = this.value;
      this.contentValue=this.value;
      this.newValue=this.value;
      let todaydate = new Date();
      this.today=format(todaydate,"YYYY-MM-DD") 
    },
  computed: {
    ...mapGetters(["user"]),
    isDisabled() {
      return (
        (typeof this.value === "string" && !this.value.trim()) ||
        (this.validationObject && this.validationObject.disabled)
      );
    },
    validationObject() {
      // this.contentValue=this.value
      let val = this.contentValue;
      let values=this.value;
      if(this.component=="currency"){
        values=this.newValue
      }
      let remainingChar = Number(this.len) - val?.length;
      let exceedChar = val?.length - Number(this.len);

      if (this.isRequired && this.isRequired === "false") {
        return {
          message: null,
          textColor: "text-green-500",
          disabled: false,
        };
      }
      if(this.label ==="description" || this.label ==="problemDescription" ||this.label ==="potentialMarket"){
        if (!val || val?.length === 0 || val =="<p></p>") {
        return {
          message: this.updateText(this.label) + " is Required.",
          textColor: "text-red-500",
          disabled: true,
        };
      }else if(val){
        let text = val?.replace(/<[^>]+>/g, '')
        if(text && text.length<1000){
          return {
          message:"Minimum limit 1000 characters.",
          textColor: "text-red-500",
          disabled: true,
        };
        }
      }
      }
      if(this.label !="coreTeamSize" && this.label !="name" && this.label !== "description" && this.label !=="problemDescription" && this.label !=="potentialMarket"){
        if (!val || val?.length === 0 || val =="<p></p>") {
        return {
          message: this.updateText(this.label) + " is Required.",
          textColor: "text-red-500",
          disabled: true,
        };
      }
      }
      
      if(this.label =="name"){
        if (!values || values?.length === 0 || values =="<p></p>") {
        return {
          message: this.updateText(this.label) + " is Required.",
          textColor: "text-red-500",
          disabled: true,
        };
       } else if(values.length === Number(this.len) ){
          return {
            message: "Character limit " + this.len + " characters reached.",
            textColor: "text-red-500",
          }
        }
      }
      
      if (this.label=="revenueDate" || this.label=="activationDate" ||this.label=="fcsDate" || this.label =="coreTeamSize" || this.label =="tags" ) {  
        if(values <= 0){   
          return {    
             message: this.updateText(this.label) + " is Required ",  
             textColor: "text-red-500",  
             disabled: true,    
            };    
          }    
        }
     
      if (val?.length > this.len) {
        // return {
        //   // message: "max. " + this.len + " characters",
        //   message: "Character limit exceeded by " + exceedChar,
        //   textColor: "text-red-500",
        //   disabled: true,
        // };
      }
      if (this.alreadyExist) {
        return {
          message: "Submission title already exists.",
          textColor: "text-red-500",
          disabled: true,
        };
      }
      if (val?.length > 0 && val?.length < this.len) {
        // return {
        //   message:
        //     "Remaining<b>" +
        //     " &nbsp; " +
        //     remainingChar +
        //     " / " +
        //     this.len +
        //     "</b> characters",
        //   textColor: "",
        //   disabled: false,
        // };
      }
      console.log(this.component);
      if(this.component=="number" || this.component=="date" || this.component=="tagging" || this.component=="text" || this.component=="singleSelect"){
        this.contentValue=this.value 
        }
      if( this.component=="currency"){
        this.contentValue=this.newValue
      }
    },
    setLabel() {
      return " Select ";
    },
    optionsList() {
      let na = [{ value: "NA", label: "NA" }];
      let opts = [...na, ...this.options];
      opts?.map((m) => {
       m.disabled = m.value === this.value ? true : false;
      });
      let result = opts.map((data, i) => {
        return { key: i, value: data.value, label: data.label };
      });
      return result;
    },
  },
  methods: {
    updateText(label) {
      switch (label) {
        case "challengeType":
          return "At least 1 theme";
          break;
        case "aboutTeam":
          return "Team data";
          break;
        case "tags":
          return "Tag";
          break;
        case "activationDate":
          return "Count to Activation Date";
          break;
        case "revenueDate":
          return "Innovation Impact";
          break;
        case "fcsDate":
          return "Launch/GA Date";
          break;
        case "problemDescription":
          return "Value proposition";
          break;
        case "potentialMarket":
          return "Result information";
          break;
        case "elevatorPitch":
          return "Risk data";
          break;
        case "testimonialAwardsIpAndOpenSource":
          return "Testimonials and awards information data";
          break;
        default:
          return _.startCase(label);
          break;
      }
    },
    formatCurrency() {
      // Remove non-numeric characters except dot
      this.newValue = this.newValue.replace(/[^0-9.]/g, '');
      // Format as currency
      if (this.newValue) {
        const numericValue = parseFloat(this.newValue);
    if (!isNaN(numericValue)) {
      const formattedValue = numericValue.toLocaleString('en-US', { style: 'decimal' })
      this.newValue = `$${formattedValue}`;
    }
      }
      
    },
    updateContent(updatedValue,label){
      function removeEmptyTags(inputString) { 
        let outputString = inputString.replace(/<[^\/>][^>]*><\/[^>]*>/g, '');  
        return outputString;
      }
      let value = removeEmptyTags(updatedValue);
      console.log(value);
    this.contentValue=value
    if (label=="notes"  ) {
        let payload = {
        user: this.user,
        index:this.index, 
        data: this.contentValue,
        noteID:this.noteID
        }
      
     this.$store.dispatch("editNote", payload);
      // await this.$store.dispatch("updateProfile", query);
      this.$emit('setOpenCloseEditNote', false);
        
      }
    },
    tagCheck(val) {
      let allTags = Object.values(this.value).filter((f) => f === val);
      if (allTags && allTags.length === 0) {
        this.tagAvaliable = false;
      } else {
        this.tagAvaliable = true;
      }
    },

    async validName(e) {
      if (this.label === "name") {
        let slug = slugify(e.target.value);
        const playbooks = feathersClient.service("playbooks");

        const baseid = process.env.VUE_APP_BASE_ID;
        var playbookResult = await playbooks.find({
          query: {
            baseId: baseid,
            status: 4,
            $select: ["name", "description", "status", "baseId", "version"],
          },
        });

        const { apiKey, accessToken } = this.user;
        const initiatives = feathersClient.service("initiatives");
        // const initiativeById = await initiatives.get(this.$route.params.id);
        const baseId = playbookResult[0].baseId;
        const andCondition = [
          {
            baseId: baseId,
            slug: slug,
          },
        ];
        const query = {
          $and: andCondition,
          $select: ["name"],
        };
        try {
          const tokenString = `Bearer ${accessToken}`;
          const result = await initiatives.find({
            query: query,
            headers: {
              "x-api-key": apiKey,
              "oauth-accesstoken": tokenString,
            },
          });
          if (result?.data?.length > 0 && result?.data[0]?._id !==this.$route.params.id) {
            this.alreadyExist = true;
            this.validationObject();
            return;
          }
          this.alreadyExist = false;
          this.validationObject();
          return;
        } catch (err) {
          return false;
        }
      }
    },
  },
};
</script>
<style scoped>
.multiselect-input,
.multiselect-placeholder,
.multiselect-options {
  font-size: 14px !important;
}

.tagCustomInput .multiselect-options,
.tagCustomInput .multiselect-caret {
  display: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
