<template>
    <TransitionRoot appear :show="openClose" as="template">
      <Dialog as="div" :open="openClose">
        <div class="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-40">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0" />
            </TransitionChild>
  
            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
  
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="
                  inline-block
                  w-full
                  max-w-md
                  p-6
                  my-8
                  overflow-hidden
                  text-left
                  align-middle
                  transition-all
                  transform
                  bg-white
                  shadow-xl
                  rounded-md
                "
              >
                <DialogTitle
                  as="h3"
                  :class="`${type === 'Delete' ? 'text-red-500' : 'text-gray-900'} text-lg font-medium leading-6`"
                >   
                {{`${type === "Delete" ? 'Delete' : 'Update'}`}} Webpage
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Are You Sure you want to {{`${type === "Delete" ? 'delete this page?' : 'set this page as Homepage'}`}} 
                  </p>
                  <!-- <span class="text-sm text-yellow-400">This action is not reversible</span> -->
                </div>
                <div class="mt-4">
                  <button
                    type="button"
                    class="
                      inline-flex
                      justify-center
                      text-sm
                      font-medium
                      mr-3
                      hover:scale-110
                      focus:outline-none
                      px-4
                      py-2
                      rounded
                      cursor-pointer
                      hover:bg-indigo-600
                      bg-indigo-500
                      text-white
                      duration-200
                      ease-in-out
                      transition
                      disabled:opacity-30
                    "
                    @click="
                      $emit(`${this.type === 'Delete' ? 'removePage' : 'setHomePage'}`)
                    "
                  >
                    {{this.type === 'Delete' ? 'Delete' : 'Confirm'}}
                  </button>
                  <button
                    type="button"
                    @click="$emit('openCloseModal', false)"
                    class="
                      inline-flex
                      justify-center
                      text-sm
                      font-medium
                      hover:scale-110
                      focus:outline-none
                      px-4
                      py-2
                      rounded
                      cursor-pointer
                      hover:bg-gray-200
                      bg-gray-100
                      text-gray-700
                      duration-200
                      ease-in-out
                      transition
                    "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>
  
  <script>
  import { ref } from "vue";
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  } from "@headlessui/vue";
  
  export default {
    name: "ConfirmationDialog",
    data() {
      return {
      };
    },
    props: {
      openClose: Boolean,
      openCloseModal: Boolean,
      type:String
    },
    components: {
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay,
      DialogTitle,
    },
    methods: {},
  };
  </script>
  