import feathersClient from '../services/feathersClient';
import moment from "moment";
const state = {
  isLoggedIn: false,
  user: null,
  redirectUrl: null,
  selectedUser: null,
  userMetaData: null,
  webPages: [],
  selectedWebPage: null,
  emailLogs: [],
  userNotes:null,
  userLogins:{},
  downloadingReport:false
}

const mutations = {
  SET_USER(state, user) {
    state.isLoggedIn = true;
    state.user = user;
  },
  LOG_OUT(state) {
    state.isLoggedIn = false;
    state.redirectUrl = null;
    state.user = null;
  },
  REDIRECT_URL(state, redirectUrl) {
    state.redirectUrl = redirectUrl;
  },
  SELECTED_USER_DATA(state, data) {
    state.selectedUser = data;
  },
  SET_USER_LOGINS(state,data){
    state.userLogins = data;
  },
  SET_LOADING(state, data) {
    state.downloadingReport = data;
  },
  USER_METADATA(state, data) {
    state.userMetaData = data;
  },
  USER_NOTES(state, data) {
    state.userNotes = data;
  },
  WEB_PAGES(state, data) {
    state.webPages = data;
  },
  SELECTED_WEB_PAGE(state, data) {
    state.selectedWebPage = data;
  },
  EMAIL_LOGS(state, data) {
    state.emailLogs = data;
  },
}

const actions = {
  login({ commit, dispatch }, user) {
    commit('SET_USER', user);
    dispatch('readInitiative');
    dispatch('readPlaybook');
    dispatch('getWorkspaceUsersList');
  },
  setRedirectUrl({ commit }, redirectUrl) {
    commit('REDIRECT_URL', redirectUrl);
  },
  logout({ commit, dispatch }) {
    localStorage.removeItem("feathers-jwt");
    commit('LOG_OUT');
    dispatch('setLogoutFlag', true);
    localStorage.removeItem('vuex');
  },
  async getUserDetails(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const accountUsersService = feathersClient.service("accountusers");
    const tokenString = `Bearer ${accessToken}`;
    try {

      let query = {
        '_id': payload.aspenifyUserId,
        $select: ['_id', 'firstName', 'lastName', 'email', 'profilePic']
      }
      const result = await accountUsersService.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });

      if (result?.data?.length !== 0 &&
        payload?.params?.userName ===
        result.data[0].email.substring(0, result.data[0].email.lastIndexOf("@"))) {
        context.commit('SELECTED_USER_DATA', result.data[0]);
        return result.data[0]
      } else {
        context.commit('SELECTED_USER_DATA', null);
      }

    } catch (error) {
      context.commit('SELECTED_USER_DATA', null);
      console.log(error)
    }
  },

  async getUserMetaData(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const accountUserMetaDataService = feathersClient.service("accountusermetadata");
    const tokenString = `Bearer ${accessToken}`;
    try {
      let query = {
        'accountUserId': payload.aspenifyUserId,
      }
      const result = await accountUserMetaDataService.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });
      context.commit('USER_METADATA', result?.data[0]?.metadata);
      return result?.data[0]?.metadata
    } catch (error) {
      context.commit('USER_METADATA', {});

      console.log(error)
    }
  },

  // async createNote(context, payload) {
  //   if(payload?.data?.value?.content && payload.data.value.content.replace(/<\/?[^>]+(>|$)/g, "").length>0){
  //     console.log(payload.data);
  //     const { accessToken } = context.rootState.user.user;
  //     const accountUserMetaDataService = feathersClient.service("accountusermetadata");
  //     const tokenString = `Bearer ${accessToken}`;
  //     try {
  //       let query = {
  //         'accountUserId': payload.user.aspenifyUserId, 
  //       }
  //       const result = await accountUserMetaDataService.find({
  //         query: query,
  //         headers: {
  //           "Authorization": tokenString
  //         },
  //       });
    
  //       if (result?.data?.length > 0) {
  //         let notes = [];
  //         console.log(result);
  //         if(result.data[0]?.metadata?.notes){
  //           notes=result.data[0].metadata.notes;
  //           notes=[...notes,payload.data.value]
  //         } else {
  //           notes=[payload.data.value]
  //         }
  //         console.log(notes);
  //         let data = await accountUserMetaDataService.patch(result.data[0]._id, {
  //           'accountUserId': payload.user.aspenifyUserId,
  //           $set: {
  //             [payload.data.label]: [...notes],
  //           }
  //         })
  //         context.commit('USER_METADATA', data?.metadata);
  //       } else {
  //         console.log(payload);
  //         const userData = {
  //           accountId: payload.user.accountId,
  //           accountUserId: payload.user.aspenifyUserId,
  //           metadata: {
  //             notes:[
  //               {
  //                 accountUserId: payload.data.value.accountUserId,
  //                 content:payload.data.value.content,
  //                 notedate: new Date()
  //               },
  //             ] 
  //           },
  //           lastUpdatedBy: payload.user.aspenifyUserId,
  //           createdBy: payload.user.aspenifyUserId,
  //           organizationId: context.rootState.user.user.organizationId
  //         };
  //         let data=await accountUserMetaDataService.create(userData);
  //         console.log(data);
  //         context.commit('USER_METADATA', data?.metadata);
  //         return result?.data[0]?.metadata
  //       }
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  // },
  async createNote(context, payload) {
    if(payload?.data?.value?.content && payload.data.value.content.replace(/<\/?[^>]+(>|$)/g, "").length>0){
      const { accessToken } = context.rootState.user.user;
      const teamResourceService = feathersClient.service("teamResources");
      const tokenString = `Bearer ${accessToken}`;
      try {
        const userData = {
          accountId: payload.user.accountId,
          type: "innovation-notes",
          metaData: {
            accountUserId: payload.data.value.accountUserId,
            content: payload.data.value.content,
            notedate: new Date(),
          },
          lastUpdatedBy: payload.user.aspenifyUserId,
          createdBy: payload.user.aspenifyUserId,
          organizationId: context.rootState.user.user.organizationId,
          suborganizationId: null,
        };
        let data = await teamResourceService.create(userData, {
          headers: {
            Authorization: tokenString,
          },
        });        
        return data?.metaData;
      } catch (error) {
        console.log(error)
      }
    }
  },

  // async editNote(context, payload) {
  //   const { accessToken } = context.rootState.user.user;
  //   const accountUserMetaDataService = feathersClient.service("accountusermetadata");
  //   const tokenString = `Bearer ${accessToken}`;
  //   try {
  //     let query = {
  //       'accountUserId': payload.user.aspenifyUserId, 
  //     }
  //     const result = await accountUserMetaDataService.find({
  //       query: query,
  //       headers: {
  //         "Authorization": tokenString
  //       },
  //     });
  //     let notes=result.data[0].metadata.notes;
  //     let filteredNotes = notes.filter((item,index) =>{
  //       if( index == payload.index){
  //         item.content=payload.data;
  //         item.notedate=new Date()
  //       }
  //       return item
  //     });
  //     // notes=[...filteredNotes,payload.data.value]
  //       let data=await accountUserMetaDataService.patch(result.data[0]._id,
  //         {'accountUserId': payload.user.aspenifyUserId,
  //          $set: { ["metadata.notes"]: [...filteredNotes],
  //          } });
  //          console.log(data);
  //       context.commit('USER_METADATA', data?.metadata);
  //       // return data?.data[0]?.metadata
  //   } catch (error) {
  //     console.log(error)
  //   }
  // },
  async editNote(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const teamResourceService = feathersClient.service("teamResources");
    const tokenString = `Bearer ${accessToken}`;
    try {
      let metaData= {
        accountUserId: payload.user.aspenifyUserId,
        content: payload.data,
        notedate: new Date(),
      }
      const result = await teamResourceService.patch(
        payload.noteID,
        {
          $set: {
            metaData: metaData,
          },
        },
        {
          headers: {
            Authorization: tokenString,
          },
        }
      );
    } catch (error) {
      console.log(error)
    }
  },
  async getAllNotes(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const teamResourceService = feathersClient.service("teamResources");
    const tokenString = `Bearer ${accessToken}`;
    try {
      let query = {
        accountId: payload.user.accountId,
        organizationId: context.rootState.user.user.organizationId,
        type: "innovation-notes",
        active:true,
        "metaData.accountUserId": payload.aspenifyUserId,
        $sort: { createdAt: -1 },
      };
      const result = await teamResourceService.find({
        headers: {
          Authorization: tokenString,
        },
        query: query,
      });
      context.commit("USER_NOTES", result.data);
      return result.data 
    } catch (error) {
      console.log(error)
    }
  },
  // async AllNote(context, payload) {
  //   const { accessToken } = context.rootState.user.user;
  //   const accountUserMetaDataService = feathersClient.service("accountusermetadata");
  //   const tokenString = `Bearer ${accessToken}`;
  //   try {
  //     let query = {
  //       'accountUserId': payload.user.aspenifyUserId,
  //     }

  //     const result = await accountUserMetaDataService.get({
  //      query:query,
  //       headers: {
  //         "Authorization": tokenString
  //       },
  //     });
  //     return result?.data[0]?.metadata  
  //   } catch (error) {
  //     console.log(error)
  //   }
  // },

  async deleteNote( context,payload) {
    const { accessToken } = context.rootState.user.user;
    const teamResourceService = feathersClient.service("teamResources");
    const tokenString = `Bearer ${accessToken}`;
    try {
      const result = await teamResourceService.remove(payload.noteID,
        {
          headers: {
            Authorization: tokenString,
          },
        }
      )
      console.log("result",result)
    } catch (error) {
      console.log(error)
    }
  },
  // async deleteNote( context,payload) {
  //   const { accessToken } = context.rootState.user.user;
  //   const accountUserMetaDataService = feathersClient.service("accountusermetadata");
  //   const tokenString = `Bearer ${accessToken}`;
  //   try {
  //     let query = {
  //       'accountUserId': payload.user.aspenifyUserId, 
  //     }
  //     const result = await accountUserMetaDataService.find({
  //       query: query,
  //       headers: {
  //         "Authorization": tokenString
  //       },
  //     });
  //     let notes=result.data[0].metadata.notes
  //     let filteredNotes = notes.filter((item,index) => index !== payload.index);
  //     let data=await accountUserMetaDataService.patch(result.data[0]._id,
  //       {'accountUserId': payload.user.aspenifyUserId,
  //        $set: { ["metadata.notes"]: [...filteredNotes],
  //        } });
  //        console.log(data);
  //     context.commit('USER_METADATA', data?.metadata);
  //     // return result?.data[0]?.metadata
  //   } catch (error) {
  //     context.commit('USER_METADATA', {});

  //     console.log(error)
  //   }
  // },
  
  async updateProfileImage(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const accountUsersService = feathersClient.service("accountusers");
    const tokenString = `Bearer ${accessToken}`;
    try {
      let query = {
        '_id': payload.user.aspenifyUserId,
      }
      const result = await accountUsersService.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });
      if (result?.data?.length !== 0) {
        let updateUser = await accountUsersService.patch(result.data[0]._id, {
          profilePic: payload.profilePic
        });
        let userData = payload.user
        userData.profilePic = updateUser.profilePic
        context.commit('SET_USER', userData);
      }
    } catch (error) {
      console.log(error)
    }
  },

  async updateProfile(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const accountUserMetaDataService = feathersClient.service("accountusermetadata");
    const tokenString = `Bearer ${accessToken}`;
    try {
      let query = {
        'accountUserId': payload.user.aspenifyUserId,
      }

      const result = await accountUserMetaDataService.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });

      if (result?.data?.length > 0) {
        await accountUserMetaDataService.patch(result.data[0]._id,
          {'accountUserId': payload.user.aspenifyUserId,
           $set: { [payload.data.label]: payload.data.value } });
        context.commit('USER_METADATA', result?.data[0]?.metadata);
      } else {
        const userData = {
          accountId: payload.user.accountId,
          accountUserId: payload.user.aspenifyUserId,
          metadata: {
            [payload.data.key]: payload.data.value
          },
          lastUpdatedBy: payload.user.aspenifyUserId,
          createdBy: payload.user.aspenifyUserId,
          organizationId: context.rootState.user.user.organizationId
        };
        await accountUserMetaDataService.create(userData);

        return result?.data[0]?.metadata
      }
    } catch (error) {
      console.log(error)
    }
  },

  async getPages(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const TeamResourceService = feathersClient.service("teamResources");
    const tokenString = `Bearer ${accessToken}`;
    try {
      let query = {
        type: "webPages",
        $sort: { 'metaData.id': 1 },
        $populate: [
          {
            path: "createdBy",
            select: "_id firstName lastName profilePic email",
          },
          {
            path: "lastUpdatedBy",
            select: "_id firstName lastName profilePic email",
          },
        ]
      }
      let result = await TeamResourceService.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });

      result = result.data.sort((a, b) => {
        return a.metaData.id - b.metaData.id;
      });
      context.commit('WEB_PAGES', result);

      return result
    } catch (error) {
      console.log(error)
    }
  },

  async getPageById(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const TeamResourceService = feathersClient.service("teamResources");
    const tokenString = `Bearer ${accessToken}`;
    try {
      let query = {
        'metaData.pageId': payload.pageId,
        $populate: [
          {
            path: "createdBy",
            select: "_id ",
          },
          {
            path: "lastUpdatedBy",
            select: "_id ",
          },
        ]
      }

      const result = await TeamResourceService.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });
      context.commit('SELECTED_WEB_PAGE', result.data[0]);
      return result.data[0]
    } catch (error) {
      console.log(error)
    }
  },

  async createPage(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const TeamResourceService = feathersClient.service("teamResources");
    const tokenString = `Bearer ${accessToken}`;
    try {
      const pageData = {
        accountId: payload.user.accountId,
        organizationId: context.rootState.user.user.organizationId,
        suborganizationId: null,
        type: 'webPages',
        active: true,
        metaData: {
          publishedContent: payload.data.draftContent,
          draftContent: payload.data.draftContent,
          name: payload.data.name,
          pageId: payload.data.name.replace(/\s+/g, '-').toLowerCase(),
          isIndexPage: false,
          id: payload.index,
        },
        lastUpdatedBy: payload.user.aspenifyUserId,
        createdBy: payload.user.aspenifyUserId
      };
      await TeamResourceService.create(pageData);


    } catch (error) {
      console.log(error)
    }
  },

  async updatePage(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const TeamResourceService = feathersClient.service("teamResources");
    const tokenString = `Bearer ${accessToken}`;
    try {
      let params = {
        "query": {
          "organizationId": payload.user.organizationId
        }

      }
      if (payload.page === "setHomePage") {
        await TeamResourceService.patch(null,
          {
            // $set: {
            "metaData.isIndexPage": false,
            // }
          }, params);
      }
      await TeamResourceService.patch(payload.id,
        {
          $set: {
            [payload.key]: payload.value,
            [payload.key2]: payload.value2,
            [payload.key3]: payload.value3,
            [payload.key4]: payload.value4,
            [payload.key5]: payload.value5,
            [payload.key6]: payload.value6
          }
        });
    } catch (error) {
      console.log(error)
    }
  },
  async removePage(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const TeamResourceService = feathersClient.service("teamResources");
    const tokenString = `Bearer ${accessToken}`;
    try {
      await TeamResourceService.remove(payload.data._id);
    } catch (error) {
      console.log(error)
    }
  },
  async getInitiativeByUser(context, payload) {
    const { accessToken, apiKey } = context.rootState.user.user;
    const initiativeService = feathersClient.service(
      "initiatives"
    );
    let query = {
      metaData: { $exists: true },
      // organizationId: context.rootState.user.user.organizationId,
      parentInitiativeId: { $exists: false },
      status: { $in: [0, 1, 2, 3, 5, 6] },
      // baseId: process.env.VUE_APP_BASE_ID,

    };


    try {
      const tokenString = `Bearer ${accessToken}`;
      const result = await initiativeService.find({
        query: query,
        headers: {
          "x-api-key": apiKey,
          "oauth-accesstoken": tokenString,
        },
      });

      return result.data;
    } catch (err) {
      context.commit("SET_LOADING_FLAG", false);
      console.log(err);
    }
  },
  async getUsersLikes(context, payload) {
    const { accessToken, apiKey } = context.rootState.user.user;
    let likeService = feathersClient.service("initiativeLikes");
    let query = {
      "accountUserIds": payload.aspenifyUserId,
      $populate: [
        {
          path: "initiativeId",
          select: "_id name metaData createdAt picture",
        },
      ]
    };

    try {
      const tokenString = `Bearer ${accessToken}`;
      const result = await likeService.find({
        query: query,
        headers: {
          "x-api-key": apiKey,
          "oauth-accesstoken": tokenString,
        },
      });

      let Likedinitiative = []
      for (let i = 0; i < result.data.length; i++) {
        Likedinitiative.push(result.data[i].initiativeId)
      }
      return Likedinitiative;
    } catch (err) {
      context.commit("SET_LOADING_FLAG", false);
      console.log(err);
    }
  },
  async getUsersFollows(context, payload) {
    const { accessToken, apiKey } = context.rootState.user.user;
    let followService = feathersClient.service("userFollows");
    let query = {
      "accountUserId": payload.aspenifyUserId,
      $populate: [
        {
          path: "initiativeIds",
          select: "_id name metaData createdAt picture",
        },
      ]
    };

    try {
      const tokenString = `Bearer ${accessToken}`;
      const result = await followService.find({
        query: query,
        headers: {
          "x-api-key": apiKey,
          "oauth-accesstoken": tokenString,
        },
      });

      return result?.data[0]?.initiativeIds;

    } catch (err) {
      context.commit("SET_LOADING_FLAG", false);
      console.log(err);
    }
  },
  async getLogs(context, payload) {
    const { accessToken } = context.rootState.user.user;
    const CustomNotifications = feathersClient.service("customnotifications");
    const tokenString = `Bearer ${accessToken}`;
    try {
      const numberOfRecordsToSkip = (payload.currentPage - 1) * payload.limit
      let query = {
        templateName: payload.templateName,
        $skip: numberOfRecordsToSkip,
        $limit: payload.limit,
        $sort: { [payload.sortBy]: payload.orderBy },
        "$populate": [
          {
            "path": "createdBy",
            "select": "firstName lastName",
          }
        ]
      }
      if(payload.search){
        query["$or"] = [{
          subject: {
            $regex: payload.search,
            $options: 'i'
          }
        }, {
          name: {
            $regex: payload.search,
            $options: 'i'
          }
        }]
      }
      if(payload.createdAt){
        query.createdAt = payload.createdAt
      }
      let result = await CustomNotifications.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });

      context.commit('EMAIL_LOGS', result);

      return result
    } catch (error) {
      console.log(error)
    }
  },

  async getUserLogins(context,payload){
    const {accessToken} = context.rootState.user.user;
    const loginEventsService = feathersClient.service("/eventlogs/logins");
    const tokenString = `Bearer ${accessToken}`;
    try {
      let query = {}
      if(payload?.from){
        query["from"] = payload.from
      }
      if(payload?.to){
        query["to"] = payload.to
      }
      let result = await loginEventsService.find({
        query: query,
        headers: {
          "Authorization": tokenString
        },
      });
      console.log(result);
      context.commit("SET_USER_LOGINS",result);
    } catch (error) {
      console.log(error)
    }
  },
  async exportLoginReport(context,payload){
    const {accessToken} = context.rootState.user.user;
    const tokenString = `Bearer ${accessToken}`;
    const ExportExcelSheets = feathersClient.service("/export/excelsheets");
    const userLogins = context.rootState.user.userLogins.logins;
    const uniqueUserLogins = context.rootState.user.userLogins.uniquelogins;
    const uniqueDataExport = []
    const dataExport = []
    context.commit('SET_LOADING', true);
    try{
      for(let users of userLogins){
        let user={};
        user.createdAt=moment(users.createdAt).format('MM-DD-YYYY');
        user.firstName = users.firstName;
        user.lastName = users.lastName;
        user.email = users.email;
        dataExport.push(user);
      }
      for(let users of uniqueUserLogins){
        let user={};
        user.createdAt=moment(users.createdAt).format('MM-DD-YYYY');
        user.firstName = users.firstName;
        user.lastName = users.lastName;
        user.email = users.email;
        uniqueDataExport.push(user);
      }
      var columnsNames = [
        { header: 'First Name', key: 'firstName', width: 25 },
        { header: 'Last Name', key: 'lastName', width: 25 },
        { header: 'Email', key: 'email', width: 35 },
        { header: 'Login Date', key: 'createdAt', width: 20 },
      ]
      let sheet1 = {
        columnsNames: columnsNames,
        dataToExport: uniqueDataExport,
        fileName: 'Unique Logins'
        };

      let sheet2 = {
            columnsNames: columnsNames,
            dataToExport: dataExport,
            fileName: 'Unique Login History'
        };

      let excelData = {
            sheets: [sheet1, sheet2]
        };
        let exportData = await ExportExcelSheets.create(excelData);
        function s2ab(s) {
          var buf = new ArrayBuffer(s.length);
          var view = new Uint8Array(buf);
          for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
          return buf;
        }
        var blob = new Blob([s2ab(atob(exportData))], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Userlogins.xlsx';
        link.click();
        context.commit('SET_LOADING', false);
    } catch (err) {
      context.commit('SET_LOADING', false);
      console.log("Error in Exporting Users ", err);
    }
  }
}

const getters = {
  isAuthenticated: (state) => state.isLoggedIn,
  user: (state) => state.user,
  redirectUrl: (state) => state.redirectUrl,
  selectedUser: (state) => state.selectedUser,
  userMetaData: (state) => state.userMetaData,
  userNotes: (state) => state.userNotes,
  webPages: (state) => state.webPages,
  selectedWebPage: (state) => state.selectedWebPage,
  emailLogs: (state) => state.emailLogs,
  userLogins:(state) => state.userLogins,
  downloadingReport:(state) => state.downloadingReport
}

const UserModule = {
  state,
  mutations,
  actions,
  getters
}

export default UserModule;