<template>
  <div v-if="!loaderFlag" class="px-6 py-6" :key="componentKey">
    <div class="flex">
      <h3 class="flex-grow text-blue-800 font-bold">Adding new Tab</h3>
    </div>
    <div class="w-12/12">
      <div class="mt-3">
        <label class="block text-sm mb-2 font-semibold"
          >Title<span class="text-red-500">&nbsp;*</span></label
        >
        <input
          v-model="formData.name"
          class="
            bg-gray-300
            appearance-none
            border-2 border-gray-300
            rounded
            shadow-md
            w-full
            py-2
            px-4
            mb-1
            text-gray-700
            leading-tight
            focus:outline-none focus:bg-white focus:border-gray-500
          "
          id="title"
          type="text"
          @input="checkName"
        />
        <p v-if="isNameAvailable" class="w-100 mt-2 text-sm text-red-600">
          Page title or Route already exists.
        </p>
      </div>
      <div class="mt-7">
        <label class="block text-sm mb-2"
          ><span class="font-semibold">HTML</span>
          <!-- <span class="text-red-500">&nbsp;*</span> -->
          (Please use tailwind CSS)</label
        >
        <textarea
          @focus="editing = true"
          v-model="formData.draftContent"
          name=""
          rows="15"
          class="
            bg-gray-300
            appearance-none
            border-2 border-gray-300
            rounded
            shadow-md
            w-full
            py-2
            px-4
            text-gray-700
            leading-tight
            focus:outline-none focus:bg-white focus:border-gray-500
          "
          id="inline-full-name"
        >
        </textarea>
      </div>
      <div class="flex mt-2">
        <div class="flex-grow">
          <button
            class="
              inline-flex
              text-white
              bg-indigo-700
              border-0
              py-2
              px-4
              focus:outline-none
              hover:bg-indigo-800
              rounded
              text-sm
              mr-2
              float-left
              disabled:opacity-50
            "
            @click="
              this.$route.params.type === 'new'
                ? createPage('save', $event)
                : updateContent('save', $event)
            "
            :disabled="!editing || isNameAvailable"
          >
            Save Draft
          </button>
          <button
            class="
              inline-flex
              text-white
              bg-indigo-700
              border-0
              py-2
              px-4
              focus:outline-none
              hover:bg-indigo-800
              rounded
              text-sm
              float-left
              disabled:opacity-50
            "
            @click="Preview"
            :disabled="editing"
          >
            Preview
          </button>
          <div class="text-xs w-28 float-left ml-1 text-blue-700 mt-0.5">
            Your webpage will open in new tab
          </div>
        </div>
        <div class="flex-end">
          <button
            class="
              inline-flex
              text-white
              bg-white
              text-indigo-500
              border-0
              hover:opacity-50
              py-2
              px-4
              focus:outline-none
              cursor-pointer
              rounded
              text-sm
              border border-indigo-500
              disabled:opacity-50 disabled:cursor-normal
            "
            @click="Cancel"
          >
            Cancel
          </button>
          <button
            class="
              inline-flex
              text-white
              bg-indigo-700
              border-0
              py-2
              px-4
              focus:outline-none
              hover:bg-indigo-800
              rounded
              text-sm
              ml-2
              disabled:opacity-50
            "
            :disabled="formData.name?.length === 0 || isNameAvailable"
            @click="
              this.$route.params.type === 'new'
                ? createPage('publish', $event)
                : updateContent('publish', $event)
            "
          >
            Publish
          </button>
        </div>
      </div>
    </div>
  </div>
  <loader-view v-if="loaderFlag" :loaderFlag="loaderFlag" loadingTitle="Loading..."> </loader-view>
</template>

<script >
import { mapGetters } from "vuex";
import LoaderView from "../../components/progressView/Activities/LoaderView.vue";
export default {
  data() {
    return {
      componentKey: 0,
      editing: false,
      isNameAvailable: false,
      loaderFlag: false,
      formData: {
        name: "",
        draftContent: "",
      },
    };
  },
  created: async function () {
    this.loaderFlag = true;
    await this.$store.dispatch("getPages");
    let payload = {
      pageId: this.$route.params.type,
    };
    await this.$store.dispatch("getPageById", payload);
    this.loaderFlag = false;
  },
  components: {
    LoaderView,
  },
  watch: {
    selectedPage() {
      this.componentKey += 1;
    },
  },
  computed: {
    ...mapGetters(["user", "webPages", "selectedWebPage"]),
    allPages() {
      return this.$store.getters.webPages;
    },
    selectedPage() {
      let sData = this.$store.getters.selectedWebPage;
      if (sData) {
        this.formData = sData?.metaData;
      } else{
        this.formData = {
          name:"",
          draftContent:""

        };
      }
      return sData;
    },
  },
  methods: {
    async createPage(actionType) {
      let payload = {
        user: this.user,
        index: this.webPages.length + 1,
        data: this.formData,
      };
      await this.$store.dispatch("createPage", payload);

      if (actionType === "save") {
        let pageType = this.formData.name.replace(/\s+/g, "-").toLowerCase();

        let payload = {
          'metaData.pageId': pageType,
        };
        await this.$store.dispatch("getPageById", payload);

        this.$router.push({
          name: "AddUpdateWebpage",
          params: { type: pageType },
        });
        this.editing = false;
      }
      if (actionType === "publish") {
        this.$router.push({ name: "WebPages", params: {} });
      }
    },
    checkName() {
      return this.webPages?.filter(
        (f) =>
          f.metaData.pageId === this.formData.name ||
          this.formData.name === f.metaData.name
      )?.length !== 0
        ? (this.isNameAvailable = true)
        : (this.isNameAvailable = false);
    },

    async updateContent(actionType) {
      let payload = {
        user: this.user,
        id: this.selectedPage._id,
      };
      if (actionType === "save") {
        payload.key = "metaData.name";
        payload.value = this.formData.name;
        payload.key2 = "metaData.draftContent";
        payload.value2 = this.formData.draftContent;

        this.editing = false;
      }

      if (actionType === "publish") {
        payload.key = "metaData.name";
        payload.value = this.formData.name;
        payload.key2 = "metaData.draftContent";
        payload.value2 = this.formData.draftContent;
        payload.key3 = "metaData.publishedContent";
        payload.value3 = this.formData.draftContent;
        payload.key4 = "lastUpdatedBy";
        payload.value4 = this.user.aspenifyUserId;
        
      }

      await this.$store.dispatch("updatePage", payload);

      if (actionType === "publish") {
        this.$router.push({ name: "WebPages", params: {} });
      }
    },
    Preview() {
      let pageName = this.$route.params.type;

      if (this.$route.params.type === "new") {
        pageName = this.formData.name.replace(/\s+/g, "-").toLowerCase();
      } else {
        pageName = this.$route.params.type;
      }

      let route = this.$router.resolve({
        name: "PagePreviewView",
        params: { page: pageName },
      });
      window.open(route.href);

      //this.$router.push({ name: "WebPages", params: {} });
    },
    Publish() {
      this.$router.push({ name: "WebPages", params: {} });
    },
    Cancel() {
      this.formData = {
          name:"",
          draftContent:""

        }
      this.$store.dispatch("setSelectedWebPage", this.formData);
      this.$router.push({ name: "WebPages", params: {} });
    },
  },
};
</script>
<style scoped>
</style>
