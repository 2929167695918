<template>
  <div>
    <toaster
      :header="toasterMessage.header"
      :text="toasterMessage.text"
      :type="toasterMessage.type"
    />
    <div>
      <div>
        <div>
          <section
            class="text-gray-600 body-font"
            v-if="isPostIdeaFlag && initiatives.length === 0 || (user.roles.length==1 && (user.roles.includes('judgeManager') || user.roles.includes('judgeMember')))"
          >
            <!-- <div v-if="loadingFlag">
              <h1>loading Idea</h1>
            </div> -->
            <loader-view
              v-if="loadingFlag || (user.roles.length==1 && (user.roles.includes('judgeManager') || user.roles.includes('judgeMember')))"
              :loaderFlag="loadingFlag"
              loadingTitle="Loading..."
            >
            </loader-view>
            <div
              v-else
            >
            <no-idea-page @postNewIdea="postNewIdea" />
            </div>
          </section>
          <div class="px-6 py-6 container mx-auto" v-else>
            <div class="flex justify-between w-full mb-2">
              <div class="mb-6 lg:mb-0">
                <div class="mb-2">
                  <h2
                    class="
                      inline-block
                      sm:text-xl
                      text-lg
                      font-medium
                      title-font
                      text-gray-900
                    "
                  >
                    All Submissions
                  </h2>
                  <div
                    v-if="pagination && pagination.totalRecords && initiatives.length !== 0"
                    class="
                      inline-block
                      rounded-full
                      bg-indigo-500
                      px-2
                      border-0
                      inline-flex
                      items-center
                      justify-center
                      text-gray-500
                      ml-2
                    "
                  >
                    <div
                      class="
                        sm:text-base
                        text-base
                        font-medium
                        title-font
                        text-gray-200
                      "
                    >
                      {{ pagination.totalRecords }}
                    </div>
                  </div>
                </div>
                <div class="h-1 w-20 bg-indigo-500 rounded"></div>
              </div>
              <div>
                <div
                  class="
                    list-style
                    bg-gray-200
                    text-sm text-gray-500
                    leading-none
                    border-2 border-gray-200
                    rounded-full
                    inline-flex
                  "
                >
                  <button
                    v-if="layout === 'list'"
                    class="
                      inline-flex
                      items-center
                      transition-colors
                      duration-300
                      ease-in
                      focus:outline-none
                      hover:text-indigo-400
                      focus:text-indigo-400
                      rounded-l-full
                      px-4
                      py-2
                      active
                    "
                    v-bind:class="{
                      'text-indigo-700': layout == 'grid',
                    }"
                    id="grid"
                    @click="layout = 'grid'"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                    <!-- <span>Grid</span> -->
                  </button>
                  <button
                    v-if="layout === 'grid'"
                    class="
                      inline-flex
                      items-center
                      transition-colors
                      duration-300
                      ease-in
                      focus:outline-none
                      hover:text-indigo-400
                      focus:text-indigo-400
                      rounded-r-full
                      px-4
                      py-2
                    "
                    v-bind:class="{
                      'text-indigo-700': layout === 'list',
                    }"
                    id="list"
                    @click="layout = 'list'"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <!-- <span>List</span> -->
                  </button>
                  <button
                    class="
                      inline-flex
                      items-center
                      transition-colors
                      duration-300
                      ease-in
                      focus:outline-none
                      hover:text-indigo-400
                      rounded-r-full
                      px-4
                      py-2
                    "
                    v-bind:class="{
                      'text-indigo-700': openFilter,
                    }"
                    id="list"
                    @click="openFilter = !openFilter"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <!-- <span>Filter</span> -->
                  </button>
                </div>
                <div class="dropdown inline-block relative z-30">
                  <button
                    class="
                      text-gray-700
                      font-semibold
                      py-2
                      px-4
                      rounded
                      inline-flex
                      items-center
                      mr-2
                    "
                    @mouseover="sordDropdownOver"
                    @mouseout="sortDropdownOut"
                  >
                    <!-- <span class="mr-1">Sort</span> -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z"
                      />
                    </svg>
                  </button>
                  <ul
                    v-show="sortDropdown"
                    @click="sortDropdown = false"
                    @mouseover="sordDropdownOver"
                    @mouseout="sortDropdownOut"
                    class="
                      dropdown-menu
                      absolute
                      text-gray-700
                      -right-0
                      w-64
                      z-50
                    "
                  >
                    <li class="">
                      <a
                        class="
                          rounded-t
                          bg-white
                          hover:bg-gray-300
                          py-2
                          px-3
                          block
                        "
                        href="#"
                        @click="
                          sortBy = { name: 1 };
                          submitSearch();
                        "
                        >Name - A - Z</a
                      >
                    </li>
                    <li class="">
                      <a
                        class="
                          rounded-t
                          bg-white
                          hover:bg-gray-300
                          py-2
                          px-3
                          block
                        "
                        href="#"
                        @click="
                          sortBy = { name: -1 };
                          submitSearch();
                        "
                        >Name - Z - A</a
                      >
                    </li>
                    <li class="">
                      <a
                        class="bg-white hover:bg-gray-300 py-2 px-3 block"
                        href="#"
                        @click="
                          sortBy = { createdAt: 1 };
                          submitSearch();
                        "
                        >Date - Oldest first</a
                      >
                    </li>
                    <li class="">
                      <a
                        class="bg-white hover:bg-gray-300 py-2 px-3 block"
                        href="#"
                        @click="
                          sortBy = { createdAt: -1 };
                          submitSearch();
                        "
                        >Date - Newest first</a
                      >
                    </li>
                    <!-- <li class="">
                  <a
                    class="bg-white hover:bg-gray-200 py-2 px-3 block"
                    href="#"
                    @click="sortBy = 'type'; submitSearch()"
                    >Type</a
                  >
                </li> -->
                  </ul>
                </div>
                <!-- <div
                  v-if="!user.roles.includes('contractor')"
                  class="inline-block relative bottom-1"
                >
                  <button
                    class="
                      inline-flex
                      text-white
                      bg-indigo-500
                      border-0
                      py-2
                      px-4
                      focus:outline-none
                      hover:bg-indigo-600
                      rounded
                      text-md
                    "
                    @click="postNewIdea"
                  >
                    New Submission
                  </button>
                </div> -->

                <!-- <div
                  v-if="user.userRole == 'workspaceOwner'"
                  class="inline-block relative bottom-1 pl-2"
                >
                  <button
                    class="
                      inline-flex
                      text-white
                      bg-indigo-500
                      border-0
                      py-2
                      px-4
                      focus:outline-none
                      hover:bg-indigo-600
                      rounded
                      text-md
                    "
                    @click="exportUserActivity"
                  >
                    Export User Activity
                  </button>
                </div> -->
                <div
                  v-if="user.userRole == 'workspaceOwner'"
                  class="inline-block relative bottom-1 pl-2"
                >
                  <button
                    class="
                      inline-flex
                      text-white
                      bg-indigo-500
                      border-0
                      py-2
                      px-4
                      focus:outline-none
                      hover:bg-indigo-600
                      rounded
                      text-md
                    "
                    @click="exportIdeas"
                  >
                    Export Submissions
                  </button>
                </div>
                <div
                  v-if="user.userRole == 'workspaceOwner'"
                  class="inline-block relative bottom-1 pl-2"
                >
                  <button
                    class="
                      inline-flex
                      text-white
                      bg-indigo-500
                      border-0
                      py-2
                      px-4
                      focus:outline-none
                      hover:bg-indigo-600
                      rounded
                      text-md
                    "
                    @click="exportJudgingData"
                  >
                    Export Judging Data
                  </button>
                </div>
                <div
                  v-if="user.userRole == 'workspaceOwner'"
                  class="
                    inline-block
                    relative
                    bottom-1
                    inline-flex
                    border-0
                    pl-2
                    focus:outline-none
                    rounded
                    text-md
                  "
                >
                    <import-file
                      @importUsers="importUsers"
                      name="Import Users"
                      label="file2"
                      id="file2"
                    />
                </div>
                <!-- <div
                  v-if="user.userRole == 'workspaceOwner'"
                  class="
                    inline-block
                    relative
                    bottom-1
                    inline-flex
                    border-0
                    pl-2
                    focus:outline-none
                    rounded
                    text-md
                  "
                >
                  <import-file
                    @importIdeas="importIdeas"
                    name="Import Ideas"
                    label="file1"
                    id="file1"
                  />
                </div> -->
                <!-- <div
                  v-if="user.userRole == 'workspaceOwner'"
                  class="
                    inline-block
                    relative
                    bottom-1
                    inline-flex
                    border-0
                    pl-2
                    focus:outline-none
                    rounded
                    text-md
                  "
                >
                  <div class="file-input" @change="onImportLikesFollowsChange">
                    <input
                      type="file"
                      accept="application/json"
                      id="file3"
                      class="file"
                    />
                    <label for="file3">
                      Import Likes & Follows
                      <p class="file-name"></p>
                    </label>
                  </div>
                </div> -->
                <!-- <div
                  v-if="user.userRole == 'workspaceOwner'"
                  class="
                    inline-block
                    relative
                    bottom-1
                    inline-flex
                    border-0
                    pl-2
                    focus:outline-none
                    rounded
                    text-md
                  "
                >
                  <import-file
                    @importBU="importBU"
                    name="Import BU"
                    label="file4"
                    id="file4"
                  />
                </div> -->
                <!-- <div
                  v-if="user.userRole == 'workspaceOwner'"
                  class="
                    inline-block
                    relative
                    bottom-1
                    inline-flex
                    border-0
                    pl-2
                    focus:outline-none
                    rounded
                    text-md
                  "
                >
                  <import-file
                    @importSkills="importSkills"
                    name="Import Skills"
                    label="file5"
                    id="file5"
                  />
                </div> -->
                <!-- <div
                  v-if="user.userRole == 'workspaceOwner'"
                  class="inline-block relative bottom-1 pl-2"
                >
                  <button
                    class="
                      inline-flex
                      text-white
                      bg-indigo-500
                      border-0
                      py-2
                      px-4
                      focus:outline-none
                      hover:bg-indigo-600
                      rounded
                      text-md
                    "
                    @click="deleteIdeas"
                  >
                    Delete Ideas
                  </button>
                </div> -->
              </div>
            </div>

            <div v-if="openFilter" class="relative z-20">
              <nav class="flex flex-col sm:flex-row border-b border-gray-400">
                <button
                  v-on:click="setFilter(false)"
                  class="
                    text-gray-600
                    py-2
                    px-6
                    block
                    hover:text-blue-500
                    focus:outline-none
                  "
                  v-bind:class="{
                    'text-blue-500': !isFilter,
                    'border-b-2': !isFilter,
                    'font-medium border-blue-500': !isFilter,
                  }"
                >
                  Search
                </button>
                <button
                  v-on:click="setFilter(true)"
                  class="
                    text-gray-600
                    py-2
                    px-6
                    block
                    hover:text-blue-500
                    focus:outline-none
                  "
                  v-bind:class="{
                    'text-blue-500': isFilter,
                    'border-b-2': isFilter,
                    'font-medium border-blue-500': isFilter,
                  }"
                >
                  Filter
                </button>
              </nav>

              <div v-if="!isFilter">
                <div class="box pt-6 mb-4">
                  <div class="box-wrapper">
                    <div
                      class="
                        bg-white
                        rounded-md
                        flex
                        items-center
                        w-full
                        p-2
                        shadow-sm
                        border border-gray-200
                      "
                    >
                      <button class="outline-none focus:outline-none">
                        <svg
                          class="ml-2 w-5 text-gray-600 h-5 cursor-pointer"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          ></path>
                        </svg>
                      </button>
                      <input
                        v-on:input="submitSearch()"
                        type="text"
                        name=""
                        id=""
                        placeholder="Search by keyword"
                        v-model="name"
                        class="
                          w-full
                          pl-4
                          pr-5
                          text
                          outline-none
                          focus:outline-none
                          bg-transparent
                        "
                      />
                      <span
                        v-if="name?.length > 0"
                        class="
                          cursor-pointer
                          float-right
                          hover:bg-gray-300 hover:rounded-r-full
                        "
                        @click="clearSearchInput"
                      >
                        <IconClose />
                      </span>
                      <!-- <input
                        v-if="user.role == 'user' || user.role == 'judgeMember' "
                        v-on:input="submitSearch()"
                        type="text"
                        name=""
                        id=""
                        placeholder="Search By Keyword (Idea Name, Market Horizon, Phase Name, Potential Solution, Theme)"
                        v-model="name"
                        class="
                          w-full
                          pl-4
                          pr-5
                          text
                          outline-none
                          focus:outline-none
                          bg-transparent
                        "
                      /> -->
                      <!---
                      <div class="select">
                        <select name="" id="" v-model="searchType" class="pl-2 pr-2 text outline-none focus:outline-none bg-transparent">
                          <option value="all" selected>All</option>
                          <option value="">Fintech</option>
                          <option value="edutech">EduTech</option>
                          <option value="healthtech">HealthTech</option>
                          <option value="cybersecurity">Cyber Security</option>
                         </select>
                      </div>
                      --->
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div>
                  <div class="inline-grid grid-cols-3 gap-3 w-full my-3">
                    <div
                      v-if="
                        judgeTeamMember ||
                        judgeTeamLead ||
                        managerAccess ||
                        userAccess
                      "
                    >
                      <div id="phase" class="flex-1">
                        <label
                          class="block text-gray-500 font-bold mb-2 pr-4"
                          for="inline-full-name"
                        >
                          Phase
                        </label>
                        <Multiselect
                          class="z-100 multi-tag"
                          mode="tags"
                          trackBy="label"
                          label="label"
                          v-model="phase"
                          placeholder="Select "
                          :options="phaseNames"
                        ></Multiselect>
                      </div>
                    </div>
                    <div
                      v-if="
                        judgeTeamMember ||
                        judgeTeamLead ||
                        managerAccess ||
                        userAccess
                      "
                    >
                      <div id="status" class="flex-1 ml-2">
                        <label
                          class="block text-gray-500 font-bold mb-2 pr-4"
                          for="inline-full-name"
                        >
                          Status
                        </label>

                        <Multiselect
                          class="z-100 multi-tag"
                          mode="tags"
                          trackBy="label"
                          label="label"
                          v-model="status"
                          placeholder="Select "
                          :options="statusOptions"
                        />
                      </div>
                    </div>
                    <div
                      v-if="
                        judgeTeamMember ||
                        judgeTeamLead ||
                        managerAccess ||
                        userAccess
                      "
                    >
                      <div id="theme" class="flex-1 ml-2">
                        <label
                          class="block text-gray-500 font-bold mb-2 pr-4"
                          for="inline-full-name"
                        >
                          <set-title
                            v-if="playbook"
                            :metaDataSchema="playbook.metaDataSchema"
                            name="challengeType"
                            defaultText="Theme"
                          />
                        </label>
                        <Multiselect
                          class="z-100 multi-tag"
                          mode="tags"
                          trackBy="label"
                          label="label"
                          v-model="challengeType"
                          placeholder="Select "
                          :options="challengeTypes"
                        ></Multiselect>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    @click="submitSearch()"
                    class="
                      mr-1
                      bg-transparent
                      hover:bg-blue-500
                      text-white
                      bg-indigo-500
                      font-semibold
                      hover:text-white
                      py-2
                      px-4
                      border border-blue-500
                      hover:border-transparent
                      rounded
                    "
                  >
                    Apply
                  </button>
                  <button
                    @click="clearFilter()"
                    class="
                      text-base
                      ml-1
                      mb-5
                      hover:scale-110
                      focus:outline-none
                      justify-center
                      px-4
                      py-2
                      rounded
                      font-bold
                      cursor-pointer
                      hover:bg-gray-200
                      bg-gray-100
                      text-gray-700
                      border
                      duration-200
                      ease-in-out
                      border-gray-600
                      transition
                    "
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <div v-if="loadingFlag">
              <circularloader />
            </div>
            <div v-if="!loadingFlag && initiatives.length == 0">
              <section class="text-gray-600 body-font">
                <info-card message="No Submission Found!" />
              </section>
            </div>
            <div
              v-if="layout === 'grid'"
              class="
                grid grid-cols-1
                sm:grid-cols-1
                md:grid-cols-2
                lg:grid-cols-4
                xl:grid-cols-4
                gap-4
              "
            >
              <div
                class="w-full bg-white rounded shadow hover:shadow-2xl"
                :class="{
                  'animate-pulse': item.status === 0,
                }"
                v-for="item in initiatives"
                :key="item.id"
              >
                <idea-grid-card
                  :item="item"
                  @cardClick="cardClick"
                  :user="user"
                />
              </div>
            </div>

            <div
              v-if="layout === 'list'"
              class="flex items-stretch flex-wrap z-10 hover:z-100 relative"
            >
              <div class="w-full flex flex-col bg-content">
                <div
                  class="flex bg-white mb-3 rounded-lg relative"
                  v-for="item in initiatives"
                  :key="item.id"
                >
                  <idea-list-card :item="item" @cardClick="cardClick" />
                </div>
              </div>
            </div>

            <div class="mt-5" v-if="pagination.totalRecords > 8">
              <pagination
                v-if="!loadingFlag && initiatives.length !== 0"
                :total-pages="pagination.totalPages"
                :current-page="pagination.currentPage"
                @pagechanged="pageChanged"
              >
              </pagination>
            </div>
          </div>
          <idea-submitted-info
            :openInfoModal="openInfoModal"
            @submittedInfoModalflag="submittedInfoModalflag"
            page="allIdea"
          >
          </idea-submitted-info>
          <UnderJudgingInfo
            :openJudgingInfoModal="openJudgingInfoModal"
            @underJudgingModalflag="underJudgingModalflag"
          >
          </UnderJudgingInfo>
        </div>
      </div>
    </div>
  </div>
  <loader-view
    v-if="exportLoading"
    :loaderFlag="exportLoading"
    loadingTitle="Exporting...."
  >
  </loader-view>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import { mapGetters } from "vuex";
import { functions, isEmpty } from "lodash";
import NoIdeaPage from "../components/NoIdeaPage.vue";
import pagination from "../components/Pagination.vue";
import { status, wsoStatus } from "../services/status";
import priority from "../services/priority";
import InfoCard from "../components/InfoCard.vue";
import SetTitle from "../components/SetTitle";
import IdeaSubmittedInfo from "../components/IdeaSubmittedInfo.vue";
import UnderJudgingInfo from "../components/JudgingPanel/UnderJudgningModal.vue";
import RichText from "../components/RichText";
import IconClose from "../components/Icon/Close.vue";
import AvatarGrouping from "../components/AvatarGrouping.vue";
import ImportFile from "../components/ImportFile.vue";
import { format } from "fecha";
import ActionSignUpIcon from "../components/ActionSignUpIcon.vue";
import IdeaListCard from "../components/IdeaListCard.vue";
import IdeaGridCard from "../components/IdeaGridCard.vue";
import LoaderView from "../components/progressView/Activities/LoaderView.vue";
import Toaster from "../components/Toaster.vue";
export default {
  components: {
    Multiselect,
    pagination,
    NoIdeaPage,
    InfoCard,
    SetTitle,
    IdeaSubmittedInfo,
    RichText,
    UnderJudgingInfo,
    IconClose,
    AvatarGrouping,
    ImportFile,
    ActionSignUpIcon,
    IdeaListCard,
    IdeaGridCard,
    LoaderView,
    Toaster,
  },
  data() {
    return {
      isFilter: false,
      value: ["My Query 2"],
      phase: [],
      status: [],
      query: [],
      source: [],
      challengeType: [],
      queryOptions: ["My Query 1", "My Query 2"],
      phaseOptions: ["Draft", "Ideate", "Validate", "Scope", "Incubate"],
      statusOptions: [],
      priorityOptions: priority,
      searchQuery: "",
      name: "",
      searchType: "all",
      sortDropdown: false,
      showTypeDropdown: false,

      sortBy: { createdAt: -1 },
      layout: "grid",
      openFilter: false,
      isPostIdeaFlag: true,
      openInfoModal: false,
      openJudgingInfoModal: false,
      shouldComponentUpdate: false,
      shouldComponentUpdateFlag: false,
      lftOptions: [
        { key: 0, value: "true", label: "Yes" },
        { key: 1, value: "false", label: "No" },
      ],
      lftSelectedOption: false,
      BUAction: [
        { value: "AppDynamics", label: "AppDynamics" },
        { value: "Cloud Services", label: "Cloud Services" },
        { value: "Collaboration", label: "Collaboration" },
        { value: "Core Hardware Platforms", label: "Core Hardware Platforms" },
        {
          value: "Customer and Partner Services",
          label: "Customer and Partner Services",
        },
        { value: "Customer Experience", label: "Customer Experience" },
        { value: "Data and Analytics", label: "Data and Analytics" },
        { value: "Enterprise Networking", label: "Enterprise Networking" },
        { value: "IoT", label: "IoT" },
        { value: "IT", label: "IT" },
        { value: "Legal", label: "Legal" },
        {
          value: "Mass-Scale Infrastructure",
          label: "Mass-Scale Infrastructure",
        },
        { value: "Meraki", label: "Meraki" },
        { value: "Operations", label: "Operations" },
        { value: "People & Communities", label: "People & Communities" },
        { value: "Sales and Marketing", label: "Sales and Marketing" },
        { value: "Security", label: "Security" },
        { value: "Supply Chain", label: "Supply Chain" },
      ],
      usersByDisplayName: [],
      loaderFlag: false,
      execSponsors: [],
      resetValue: false,
    };
  },

  created: function () {
    this.$store.dispatch("getAccessToken", "");
    this.statusOptions =
      this.user.userRole === "workspaceOwner" ? wsoStatus : status;
    if (this?.$route?.params?.openFilter) {
      this.name = this.$route.params.searchText;
      setTimeout(() => this.submitSearch(), 100);
    }
    if (this?.$route?.params?.dashboardFilter) {
      this.name = this.$route.params.searchText;
      this.phase = [this.$route.params.phase];
      this.status = [this.$route.params.status];
      if (this?.$route?.params?.color === "Others") {
        this.bogFramework = [
          "Unassigned",
          "undefined",
          undefined,
          "Others",
          "",
          "NA",
        ];
      } else {
        this.bogFramework = [this.$route.params.color];
      }
      this.submitSearch();
    }

    //by default show status "active" as selected in submission library
    this.status = [1];
    this.searchQuery = "";
    this.pageChanged(1);
    setTimeout(() => {
      if (this.autoInitiativeInAction) {
        this.openInfoModal = true;
      }
    }, 100);
  },
  mounted() {},
  beforeMount: function () {},
  beforeUnmount: function () {},
  beforeUpdate: function () {
    if (this?.$route?.params?.openFilter) {
      this.shouldComponentUpdateFlag = this?.$route?.params?.enterButtonPressed;
    }
    // if (this?.$route?.params?.openFilter && !this.shouldComponentUpdate) {
    //   this.shouldComponentUpdate = true;
    //   this.openFilter = true;
    //   this.name = this.$route.params.searchText;
    //   setTimeout(() => this.submitSearch(), 100);
    // }
  },
  updated: function () {},
  computed: {
    ...mapGetters([
      "initiatives",
      "loadingFlag",
      "pagination",
      "user",
      "phaseNames",
      "marketHorizons",
      "challengeTypes",
      "sources",
      "bogFrameworks",
      "playbook",
      "autoInitiativeInAction",
      "exportLoading",
      "msGraphAccessToken",
      "toasterMessage",
    ]),
    managerAccess() {
      return this.user?.roles.includes("manager");
    },
    userAccess() {
      return this.user?.roles.includes("user");
    },
    judgeTeamLead() {
      return this.user?.roles.includes("judgeManager");
    },
    judgeTeamMember() {
      return this.user?.roles.includes("judgeMember");
    },
    teamMembers(item) {},
  },
  watch: {
    // wheeen searchQuery is reset
    searchQuery: function (newValue, oldValue) {
      if (isEmpty(newValue)) {
        this.pageChanged(1);
      }
    },
    shouldComponentUpdateFlag() {
      // this.openFilter = true;
      this.name = this.$route.params.searchText;
      this.submitSearch();
    },
  },
  methods: {
    handleStateValue(key, value) {
      if (key === "execSponsors") {
        this.execSponsors = value[0];
      }
      if (key === "opsPoc") {
        this.opsPoc = value[0];
      }
      if (key === "pmPoc") {
        this.pmPoc = value[0];
      }
      if (key === "teamLeadName") {
        this.teamLeadName = value[0];
      }
      if (key === "teamMemberName") {
        this.teamMemberName = value[0];
      }
      if (key === "teamMentorName") {
        this.teamMentorName = value[0];
      }
    },
    openModal() {
      if (this.autoInitiativeInAction) {
        this.openInfoModal = true;
      }
    },
    submittedInfoModalflag(open) {
      this.openInfoModal = open;
    },
    underJudgingModalflag(open) {
      this.openJudgingInfoModal = open;
    },
    postNewIdea: function () {
      this.$router.push({ name: "NewIdea" });
    },
    exportIdeas: function () {
      this.$store.dispatch("exportIdeas", {});
    },
    exportJudgingData: function () {
      this.$store.dispatch("exportJudgingData", {});
    },
    deleteIdeas: function () {
      this.$store.dispatch("deleteAllIdeas", {});
    },
    importIdeas: async function (collection) {
      this.$store.dispatch("importIdeas", collection);
    },
    exportUserActivity: function () {
      this.$store.dispatch("exportUserActivity", {});
    },
    setFilter: function (option) {
      this.isFilter = option;
      this.marketHorizons.map((data) => {
        data.disabled = false;
      });
      this.sources.map((data) => {
        data.disabled = false;
      });
      this.priorityOptions.map((data) => {
        data.disabled = false;
      });
      this.bogFrameworks?.map((data) => {
        data.disabled = false;
      });
    },
    selectIdea: function (ideaId, idea) {
      this.$store.dispatch("setSelectedInitiative", idea);
      if (idea.status == -1) {
        this.$router.push({ name: "EditIdea" });
      } else {
        this.$router.push({ name: "IdeaDetails", params: { id: ideaId } });
        window.scrollTo(0, 0);
      }
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    clearFilter() {
      this.status = [];
      this.challengeType = [];
      this.marketHorizon = [];
      this.source = [];
      this.priority = [];
      this.phase = [];
      this.bogFramework = [];
      this.lftValues = [];
      this.teamLeadName = [];
      this.teamMemberName = [];
      this.teamMentorName = [];
      this.opsPoc = [];
      this.pmPoc = [];
      this.execSponsors = [];
      this.lookingForTalentValue = [];
      this.buAction = [];
      this.lftSelectedOption = false;
      this.resetValue = true;
      this.submitSearch();
    },

    submitSearch: _.debounce(async function (data) {
      this.resetValue = false;
      let searchedString = "";
      let searchedKeywords = [];
      this.isPostIdeaFlag = false;
      let query = {
        status: this.status,
        phase: this.phase,
        "metaData.challengeType": { $in: this.challengeType },
        "metaData.marketHorizon": { $in: this.marketHorizon },
        "metaData.managementFields.buAction": { $in: this.buAction },
        "metaData.source": { $in: this.source },
        "metaData.bogFramework": { $in: this.bogFramework },
        priority: { $in: this.priority },
        "metaData.isTalentRequired": this.lookingForTalentValue,
        "metaData.talent": { $in: this.lftValues },
      };
      if (this.name.startsWith('"') && this.name.endsWith('"')) {
        searchedString = this.name;
        searchedString = searchedString.trim();
        searchedString = searchedString.slice(1, -1);
        query.name = searchedString;
      } else {
        if (this.name?.length > 0) {
          searchedString = this.name;
          searchedString = searchedString.trim();
          searchedKeywords = searchedString.split(" ");
          query.name = searchedKeywords;
        }
      }

      await this.$store.dispatch("getAllInitiatives", {
        currentPage: 1,
        searchQuery: query,
        teamLeadName: this.teamLeadName,
        teamMemberName: this.teamMemberName,
        teamMentorName: this.teamMentorName,
        opsPoc: this.opsPoc,
        pmPoc: this.pmPoc,
        execSponsors: this.execSponsors,
        sortBy: this.sortBy,
      });
    }, 300),
    pageChanged(page) {
      let searchedKeywords = [];
      let searchedString = "";
      let query = {
        status: this.status,
        phase: this.phase,
        "metaData.challengeType": { $in: this.challengeType },
        "metaData.marketHorizon": { $in: this.marketHorizon },
        "metaData.managementFields.buAction": { $in: this.buAction },
        "metaData.source": { $in: this.source },
        "metaData.bogFramework": { $in: this.bogFramework },
        priority: { $in: this.priority },
        "metaData.isTalentRequired": this.lookingForTalentValue,
        "metaData.talent": { $in: this.lftValues },
      };

      if (this.name?.length > 0) {
        searchedString = this.name;
        searchedString = searchedString.trim();
        searchedKeywords = searchedString.split(" ");
        query.name = searchedKeywords;
      }
      this.$store.dispatch("getAllInitiatives", {
        currentPage: page,
        searchQuery: query,
        teamLeadName: this.teamLeadName,
        teamMemberName: this.teamMemberName,
        teamMentorName: this.teamMentorName,
        opsPoc: this.opsPoc,
        pmPoc: this.pmPoc,
        execSponsors: this.execSponsors,
        sortBy: this.sortBy,
      });
    },
    sordDropdownOver: function () {
      this.sortDropdown = true;
    },
    sortDropdownOut: function () {
      this.sortDropdown = false;
    },

    cardClick: function (item) {
      // if (item.subInitiatives?.length !== 0) {
      //   this.underJudgingModalflag(true);
      // } else
      if (item.status === 0) {
        this.submittedInfoModalflag(true);
      } else {
       this.selectIdea(item.id, item);
      }
    },

    selectIdeaNewTab: function (ideaId, idea) {
      this.$store.dispatch("setSelectedInitiative", idea);
      if (idea.status == -1) {
        this.$router.push({ name: "EditIdea" });
      } else {
        this.$router.push({ name: "IdeaDetails", params: { id: ideaId } });
        window.scrollTo(0, 0);
      }
    },
    clearSearchInput: function () {
      this.name = "";
      let searchedKeywords = [];
      // this.submitSearch = _.debounce(async function () {}, 500);
      let searchedString = "";
      this.isPostIdeaFlag = false;
      let query = {
        status: this.status,
        phase: this.phase,
        "metaData.challengeType": { $in: this.challengeType },
        "metaData.marketHorizon": { $in: this.marketHorizon },
        "metaData.managementFields.buAction": { $in: this.buAction },
        "metaData.source": { $in: this.source },
        "metaData.bogFramework": { $in: this.bogFramework },
        priority: { $in: this.priority },
        "metaData.isTalentRequired": this.lookingForTalentValue,
        "metaData.talent": { $in: this.lftValues },
      };
      if (this.name?.length > 0) {
        searchedString = this.name;
        searchedString = searchedString.trim();
        searchedKeywords = searchedString.split(" ");
        query.name = searchedKeywords;
      }
      this.$store.dispatch("getAllInitiatives", {
        currentPage: 1,
        searchQuery: query,
        teamLeadName: this.teamLeadName,
        teamMemberName: this.teamMemberName,
        teamMentorName: this.teamMentorName,
        opsPoc: this.opsPoc,
        pmPoc: this.pmPoc,
        execSponsors: this.execSponsors,
        sortBy: this.sortBy,
      });
    },
    lftFlagChanged: function (obj) {
      if (obj === "true") {
        this.lftSelectedOption = true;
      } else {
        this.lftSelectedOption = false;
        this.lftValues = [];
      }
    },
    onImportUserChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.readFileUsers(files[0]);
    },
    importUsers: async function (collection) {
      this.$store.dispatch("importUsers", collection);
    },
    readFileUsers(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        let json = JSON.parse(e.target.result);
        this.$store.dispatch("importUsers", json);
      };
      reader.readAsText(file);
    },
    onImportLikesFollowsChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.readFileLikesFollows(files[0]);
    },
    readFileLikesFollows(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        let json = JSON.parse(e.target.result);
        this.$store.dispatch("importLikesFollows", json);
      };
      reader.readAsText(file);
    },
    importBU: async function (collection) {
      console.log("importBU ", collection);
      this.$store.dispatch("importBU", collection);
    },
    importSkills: async function (collection) {
      console.log("importSkills ", collection);
      this.$store.dispatch("importSkills", collection);
    },
    // setLoadeFlag(flag) {
    //   this.loaderFlag = flag;
    // },
    // async displayNameFilterSearch(value, selectedValues) {
    //   this.setLoadeFlag(true);
    //   let payload = {
    //     value: value,
    //     accessToken: this.msGraphAccessToken,
    //   };
    //   let result = await this.$store.dispatch("getUsersByDisplayName", payload);

    //   if (selectedValues && selectedValues.length > 0) {
    //     let filteredResult = result.filter((e) => {
    //       return !selectedValues.some(
    //         (item) => item.email?.toLowerCase() === e.email?.toLowerCase()
    //       );
    //     });
    //     this.usersByDisplayName = filteredResult;
    //   } else {
    //     this.usersByDisplayName = result;
    //   }
    //   this.setLoadeFlag(false);
    // },
    // autoSaveSuggestion(users) {
    //   this.execSponsors.push(users.name);
    // },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.multi-tag {
  background: #ffffff;
  border-radius: 5px;
}

.multiselect-option.is-pointed {
  background: none;
}

.multiselect-option.is-pointed:hover {
  background: #999fa8;
}
.disableDropdown .multiselect-input {
  cursor: not-allowed !important;
}
.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file-input label {
  display: block;
  position: relative;
  padding: 8px 16px;
  background: rgb(0, 188, 235);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  cursor: pointer;
  transition: transform 0.2s ease-out;
}

.file-name {
  position: absolute;
  bottom: -35px;
  left: 10px;

  color: #555;
}

input:hover + label,
input:focus + label {
  background: #3ba4e0;
}

/* Adding an outline to the label on focus */
input:focus + label {
}
</style>
