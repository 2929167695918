<template>
  <div class="clear-both">
    <div>
      <button
        @click="checkSubmissionStatus"
        class="
                  inline-flex
                  text-white
                  bg-indigo-500
                  border-0
                  py-2
                  px-4
                  focus:outline-none
                  hover:bg-indigo-600
                  rounded
                  text-md
                "
      >
        Add Examples
      </button>
    </div>
    <dashboard-modal
      :uppy="uppy"
      :open="isUppyOpen"
      :props="{
        trigger: '#uppy-trigger',
        onRequestCloseModal: handleClose,
        proudlyDisplayPoweredByUppy: false,
        showLinkToFileUploadResult: false,
      }"
    />
  </div>
</template>

<script>
import Uppy from "@uppy/core";
import { DashboardModal } from "@uppy/vue";
import Tus from "@uppy/tus";
import { mapGetters } from "vuex";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { PDFDocument, PDFName } from "pdf-lib";
import AttachmentIcon from "../components/Icon/AttachmentIcon.vue";

export default {
  props: {
    maxFileSizeInBytes: Number,
    accessToken: String,
    attachments: Array,
    examples: Array,
  },
  data() {
    return {
      previewPath: null,
      isUppyOpen: false,
      disabled: true,
      metadata: {},
    };
  },
  computed: {
    ...mapGetters(["user", "enableSubmission"]),
  },
  components: {
    DashboardModal,
    AttachmentIcon,
  },
  beforeDestroy() {
    this.resetUploader();
    this.uppy.close();
  },
  created() {
    this.instantiateUppy();
  },
  methods: {
    async instantiateUppy() {
      this.$emit("changeTodo");
      this.uppy = Uppy({
        debug: false,
        autoProceed: false,
        allowMultipleUploads: false,
        restrictions: {
          maxFileSize: this.maxFileSizeInBytes,
          minNumberOfFiles: 1,
          maxNumberOfFiles: 1,
          allowedFileTypes: ["application/pdf"],
        },
        meta: { accountId: this.user.accountId },
      }).use(Tus, {
        headers: {
          Authorization: this.accessToken,
        },
        removeFingerprintOnSuccess: true,
        endpoint: `${process.env.VUE_APP_FILEUPLOADURL}/files/`,
      });
      this.uppy.on("file-added", async (file) => {
        this.uppy.setFileState(file.id, { isPaused: true });
        await this.extractMetadataFromPDF(file.data);
        if(!this.metadata || !this.metadata.name || !this.metadata.image || !this.metadata.problemDescription){ 
          this.uppy.info("Metadata extraction failed. Please try again.");
          this.uppy.removeFile(file.id);
          this.uppy.reset();
          return;
        }
        if(this.metadata && this.metadata.name && this.examples && this.examples.length > 0){
            for (let example of this.examples){
              if(example.metaData.attachment[0].metadata.name === this.metadata.name){
                this.uppy.info("Example already added. Please upload another example.");
                this.uppy.removeFile(file.id);
                this.uppy.reset();
                return;
              }
            }
        }
        this.uppy.setFileState(file.id, { isPaused: false });
      });
      this.uppy.on("complete", async (event) => {
        this.$emit("changeTodo");
        if (event.successful[0] !== undefined) {
          for (let index = 0; index < event.successful.length; index++) {
            const element = event.successful[index];
            let document = {};
            document.documentName = `${element.name}`;
            let documentNameFromS3 = element.uploadURL.split("/").pop();
            document.documentId = documentNameFromS3.split(".").slice(0, -1)[0];
            document.uploadedBy = this.user.aspenifyUserId;
            document.uploadedAt = new Date();
            document.metadata = this.metadata;
            this.attachments.unshift(document);
          }
          this.$emit("fileUploaded", this.attachments);
          this.disabled = false;
        }
      });
    },  
    handleClose() {
      this.isUppyOpen = false;
      this.uppy.reset();
    },
    updatePreviewPath({ path }) {
      this.previewPath = path;
      return this;
    },
    resetUploader() {
      this.uppy.reset();
      this.disabled = true;
      return this;
    },
    confirmUpload() {},
    async checkSubmissionStatus() {
      this.isUppyOpen = !this.isUppyOpen;
    },

    async extractMetadataFromPDF(file) {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    // Extract standard metadata
    const standardMetadata = {
      title: pdfDoc.getTitle() || "N/A",
      author: pdfDoc.getAuthor() || "N/A",
      subject: pdfDoc.getSubject() || "N/A",
      keywords: pdfDoc.getKeywords() || "N/A",
      creator: pdfDoc.getCreator() || "N/A",
    };
    // Extract custom metadata from the Info dictionary
    const customMetadata = await this.extractCustomMetadata(pdfDoc);
    this.metadata = customMetadata;
  } catch (error) {
    console.error("Error extracting metadata:", error);
  }
},

async extractCustomMetadata(pdfDoc) {
  try {
    // Access the Info dictionary reference from the PDF trailerInfo
    const trailerInfo = pdfDoc.context.trailerInfo;
    if (!trailerInfo) {
      console.warn("No trailerInfo found in the PDF.");
      return null;
    }
    const infoDictRef = trailerInfo.Info;
    if (!infoDictRef) {
      console.warn("No Info dictionary reference found in the PDF.");
      return null;
    }
    // Look up the Info dictionary using the reference
    const infoDict = pdfDoc.context.lookup(infoDictRef);
    if (!infoDict) {
      console.warn("Failed to look up the Info dictionary.");
      return null;
    }
    // Extract the custom metadata field
    const metadataEntry = infoDict.get(PDFName.of('metadata'));
    if (!metadataEntry) {
      console.warn("No custom metadata found in the Info dictionary.");
      return null;
    }
    let metadataStr = "";
    if (metadataEntry) {
      const metadataObj = pdfDoc.context.lookup(metadataEntry);
      if (!metadataObj) {
        console.warn("Failed to resolve metadata reference.");
        return null;
      }
      metadataStr = metadataObj.decodeText();
    } else {
      metadataStr = metadataEntry.decodeText();
    }
    const customMetadata = JSON.parse(metadataStr);
    return customMetadata;

  } catch (error) {
    console.error("Error extracting custom metadata:", error);
    return null;
  }
}
  },
};
</script>

<style scoped>
</style>
