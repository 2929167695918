import { createApp } from 'vue';
import { applyPolyfills, defineCustomElements } from 'web-component-aspenify/loader';

import App from './App.vue';
import router from './router';
import store from "./store/store";
import { setupAuth0, auth0Plugin } from './services/auth0.js';
import './assets/main.css';
import Idle from 'idle-js';
import AppLoading from './views/AppLoading.vue';
import DashboardLayout from './components/DashboardLayout.vue';
import EmptyLayout from './components/EmptyLayout.vue';
import Autocomplete from './components/Autocomplete.vue';
import AutocompleteSingleSelect from './components/AutocompleteSingleSelect.vue';
import NotFound from './views/404'
import AdUsersDropdown from './components/AdUserDropdown.vue'

import tooltip from "./directives/tooltip.js";
import "./assets/tooltip.css";

import VCalendar from 'v-calendar';
import CircularLoader from './components/CircularLoader.vue'
import Checkbox from './components/Checkbox.vue'
const domain = process.env.VUE_APP_AUTH0_DOMAIN;
const client_id = process.env.VUE_APP_AUTH0_CLIENT_ID;

// Mount the loading scren
createApp(AppLoading)
  .use(auth0Plugin)
  .mount('#app-loading')

function callbackRedirect(appState) {
  const targetUrl = appState && appState.targetUrl ? appState.targetUrl : null;

  if (targetUrl && targetUrl != '/') {
    window.location.replace(targetUrl);
  }

  window.history.replaceState({}, document.title, targetUrl);
}

applyPolyfills().then(() => {
  defineCustomElements(window);
});

// Setup Auth0 then mount the app.
setupAuth0({ domain, client_id, store, callbackRedirect }).then(async () => {

  const app = createApp(App);

  app.component('default-layout', DashboardLayout);
  app.component('empty-layout', EmptyLayout);
  app.component('autocomplete', Autocomplete);
  app.component('autocompletesingleselect', AutocompleteSingleSelect);
  app.component('circularloader', CircularLoader);
  app.component('NotFound', NotFound); 
  app.component('AdUsersDropdown', AdUsersDropdown)
  app.component('Checkbox', Checkbox)
  app.directive("tooltip", tooltip);
  app.use(VCalendar, {})
  app.use(auth0Plugin);
  app.use(store);
  app.use(router);
  const routerInstance = router;
  const idle = new Idle({
    idle: 300000, 
    onIdle: () => {
      console.log('User is idle. Logging out...');
      routerInstance.push({ name: "Logout" });
    }
  });

  idle.start();
  app.mount('#app');


});