<template>
  <div class="px-6 py-4 container mx-auto">
    <div class="flex justify-between items-center w-full mb-2">
      <div class="mb-6 lg:mb-0">
        <div class="mb-2">
          <h2
            class="
                      inline-block
                      sm:text-xl
                      text-lg
                      font-medium
                      title-font
                      text-gray-900
                    "
          >
            Examples
          </h2>
          <div
            v-if="
              pagination && pagination.totalRecords && examples.length !== 0
            "
            class="
                      inline-block
                      rounded-full
                      bg-indigo-500
                      px-2
                      border-0
                      inline-flex
                      items-center
                      justify-center
                      text-gray-500
                      ml-2
                    "
          >
            <div
              class="
                        sm:text-base
                        text-base
                        font-medium
                        title-font
                        text-gray-200
                      "
            >
              {{ pagination.totalRecords }}
            </div>
          </div>
        </div>
        <div class="h-1 w-20 bg-indigo-500 rounded"></div>
      </div>
      <div v-if="managerAccess" class="flex justify-end">
        <example-attachment
          class="pt-4"
          :accessToken="accessToken"
          :examples="examples"
          :attachments="attachments"
          @fileUploaded="fileUploaded"
        />
      </div>
    </div>
  </div>
  <loader-view
      v-if="loadingFlag"
      :loaderFlag="loadingFlag"
      loadingTitle="Loading..."
    >
    </loader-view>
  <section
    class="px-6 py-2 text-gray-600 body-font"
    v-if="!loadingFlag && examples.length === 0"
  >
    <div class="container mx-auto flex items-center justify-center flex-col">
      <img src="@/assets/images/Progress.png" width="300" />
      <div class="text-center lg:w-2/3 w-full">
        <div class="mb-8 mt-8 leading-relaxed">
          <h3 class="text-2xl text-blue-900">No Example to show</h3>
        </div>
      </div>
    </div>
  </section>
  <div v-else class="px-6 py-4 container mx-auto">
    <div
      class="
      grid grid-cols-1
      sm:grid-cols-1
      md:grid-cols-2
      lg:grid-cols-4
      xl:grid-cols-4
      gap-4
    "
    >
      <div
        class="w-full bg-white rounded shadow hover:shadow-2xl"
        v-for="example in examples"
        :key="example._id"
      >
        <div class="cursor-pointer" @click="viewExample(example)">
          <div class="relative pb-48 overflow-hidden">
            <img
              class="
                absolute
                inset-0
                h-full
                w-full
                object-cover
                rounded-tl rounded-tr
              "
              :src="example.metaData.attachment[0].metadata.image"
              alt="Example Image"
            />
          </div>
          <div class="border-2 border-gray-200"></div>
          <div style="min-height: 120px" class="p-4">
            <div class="flex justify-between items-center my-2">
              <h2
                class=" text-gray-800 font-bold break-words line-clamp-2 w-11/12"
              >
                {{ example.metaData.attachment[0].metadata.name }}
              </h2>
            </div>
            <div
              class="line-clamp-3"
              v-if="example.metaData.attachment[0].metadata?.problemDescription"
            >
              <div
                class="text-gray-500 break-words"
                v-if="
                  example.metaData.attachment[0].metadata.problemDescription
                    ?.length < 100
                "
              >
                {{ example.metaData.attachment[0].metadata.problemDescription }}
              </div>
              <div class="text-gray-500 break-words" v-else>
                {{
                  example.metaData.attachment[0].metadata.problemDescription?.substring(
                    0,
                    100
                  ) + " ..."
                }}
              </div>
            </div>
            <div v-else class="text-gray-500">NA</div>
            <div class="flex justify-end items-center">
              <button v-if="managerAccess"
                class="text-red-500 hover:text-red-700"
                @click.stop="openModal(example._id)"
              >
                <!-- ✖ -->
                <svg baseProfile="full" height="18" version="1.1" width="15.5" xmlns="http://www.w3.org/2000/svg" xmlns:ev="http://www.w3.org/2001/xml-events" xmlns:xlink="http://www.w3.org/1999/xlink"> <defs /> <path d="M 8,13 7,14 7,34 8,35 8,36 9,36 10,37 26,37 27,36 27,35 28,34 28,14 27,13 Z" fill="#E56363" transform="scale(0.5)" /> <path d="M 14,6 13,7 12,7 11,8 6,8 6,11 29,11 29,8 23,8 21,6 Z" fill="#E56363" transform="scale(0.5)" /> </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5" v-if="pagination.totalRecords > 8">
        <pagination
          v-if="!loadingFlag && examples.length !== 0"
          :total-pages="pagination.totalPages"
          :current-page="pagination.currentPage"
          @pagechanged="pageChanged"
        >
        </pagination>
      </div>
  </div>
  <DeleteExampleConfirm
   :openClose="openClose"
   :sampleId="selectedSampleId"
   @openCloseModal="openModal"
   @removeExample="deleteExample"
  />
</template>

<script lang="js">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import ExampleAttachment from "../components/ExampleAttachment.vue";
import { stripHtml } from "string-strip-html";
import pagination from "../components/Pagination.vue";
import DeleteExampleConfirm from "../components/confirmationModal/DeleteExampleConfirm.vue";
import InfoCard from "../components/InfoCard.vue";
export default defineComponent({
  data() {
    return {
      attachments: [],
      loadingFlag :false,
      openClose: false,
      selectedSampleId: "",
    };
  },
  components: {
    ExampleAttachment,
    pagination,
    DeleteExampleConfirm,
    InfoCard,
  },
  created() {
    this.loadingFlag = true;
    this.$store.dispatch("getExamples",{
        currentPage: 1,
      });
    this.loadingFlag = false;
  },
  computed: {
    ...mapGetters([
      "exampleSet",
      "user",
      "pagination",
    ]),
    accessToken() {
      const { accessToken } = this.user;
      return `Bearer ${accessToken}`;
    },
    managerAccess() {
      return this.user && this.user.roles.includes("manager");
    },
    examples() {
      if (this.exampleSet?.length > 0) {
      const processedExamples = this.exampleSet.map(example => ({
        ...example,
        metaData: {
          ...example.metaData,
          attachment: example.metaData?.attachment?.map((att, index) =>
            index === 0
              ? {
                  ...att,
                  metadata: {
                    ...att.metadata,
                    problemDescription: att.metadata?.problemDescription
                      ? stripHtml(att.metadata.problemDescription).result
                      : ""
                  }
                }
              : att
          )
        }
      }));
      return processedExamples;
    }
    return [];
  }
  },
  methods: {
    async fileUploaded(uploadedFiles) {
      try {
        if(uploadedFiles.length === 0) return;
        if(!uploadedFiles[0].metadata || Object.keys(uploadedFiles[0].metadata).length === 0) {
          return;
        }
        this.loadingFlag = true;
        await this.$store.dispatch("createExample", uploadedFiles);
        this.loadingFlag = false;
      } catch (err) {
        console.log(err);
      }
    },
    async deleteExample(exampleId) {
      try {
        this.loadingFlag = true;
        await this.$store.dispatch("deleteExample", exampleId);
        this.openModal();
        this.loadingFlag = false;
      } catch (err) {
        console.log(err);
      }
    },
    async viewExample(example) {
      try {
        await this.$store.dispatch("viewExamplePdf", example.metaData.attachment[0]);
      } catch (err) {
        console.log(err);
      }
    },
    pageChanged(page) {
      this.$store.dispatch("getExamples", {
        currentPage: page,
      });
    },
    openModal(sampleId) {
      this.openClose = !this.openClose;
      this.selectedSampleId = sampleId ? sampleId : "";
    },

  },
  setup() {}
});
</script>

<style scoped></style>
