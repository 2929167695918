<template>
  <div>
    <section class="text-gray-600 body-font" v-if="isPostIdeaFlag && initiatives.length === 0">
        <loader-view
          v-if="loadingFlag"
          :loaderFlag="loadingFlag"
          loadingTitle="Loading..."
        >
        </loader-view>
        <div v-else>
          <no-idea-page @postNewIdea="postNewIdea" />
        </div>
    </section>
    
    <div v-else>
      <div>
        <div class="px-6 py-4 container mx-auto">
          <div class="flex justify-between w-full mb-4">
            <div class="mb-6 lg:mb-0">
              <div class="mb-2">
                <h2
                  class="
                    inline-block
                    sm:text-xl
                    text-lg
                    font-medium
                    title-font
                    text-gray-900
                  "
                >
                  My Submissions
                </h2>
                <div
                  v-if="pagination && pagination.totalRecords"
                  class="
                    inline-block
                    rounded-full
                    bg-indigo-500
                    px-2
                    border-0
                    inline-flex
                    items-center
                    justify-center
                    text-gray-500
                    ml-2
                  "
                >
                  <p
                    class="
                      sm:text-base
                      text-base
                      font-medium
                      title-font
                      text-gray-200
                    "
                  >
                    {{ pagination.totalRecords }}
                  </p>
                </div>
              </div>
              <div class="h-1 w-20 bg-indigo-500 rounded"></div>
            </div>
            <div v-if="(!user.roles.includes('contractor') && !user.roles.includes('manager')) && playbook?.createInitiative">
              <button
                class="
                  inline-flex
                  text-white
                  bg-indigo-500
                  border-0
                  py-2
                  px-4
                  focus:outline-none
                  hover:bg-indigo-600
                  rounded
                  text-md
                "
                @click="postNewIdea"
              >
                New Submission
              </button>
            </div>
          </div>
          <div v-if="!loadingFlag && initiatives.length == 0">
              <section class="text-gray-600 body-font">
                <info-card message="No Submission Found!" />
              </section>
          </div>
          <div
            class="
              grid grid-cols-1
              sm:grid-cols-1
              md:grid-cols-2
              lg:grid-cols-4
              xl:grid-cols-4
              gap-4
            "
          >
            <!-- <idea-grid-placeholder
            :idea = ideaProcessStatus?.idea
          /> -->
            <div
              class="w-full bg-white rounded shadow hover:shadow-2xl"
              :class="{
                'animate-pulse': item.status === 0,
              }"
              v-for="item in initiatives"
              :key="item.id"
            >
              <idea-grid-card :item="item" @cardClick="cardClick" :user="user"/>
            </div>
          </div>

          <div class="mt-5" v-if="pagination.totalRecords > 8">
            <pagination
              v-if="!loadingFlag && initiatives.length !== 0"
              :total-pages="pagination.totalPages"
              :current-page="pagination.currentPage"
              @pagechanged="pageChanged"
            >
            </pagination>
          </div>
        </div>
      </div>
    </div>

    <idea-submitted-info
      :openInfoModal="openInfoModal"
      @submittedInfoModalflag="submittedInfoModalflag"
      page="myIdea"
    >
    </idea-submitted-info>
  </div>
  <toaster
    :header="ideaProcessStatus.header"
    :subheader="ideaProcessStatus.subheader"
    :text="ideaProcessStatus.text"
    :type="ideaProcessStatus.type"
  />
  <loader-view
    v-if="loadingFlag"
    :loaderFlag="loadingFlag"
    loadingTitle="Ideas"
  >
  </loader-view>
</template>
<script>
import { mapGetters } from "vuex";
import pagination from "../components/Pagination.vue";
import NoIdeaPage from "../components/NoIdeaPage.vue";
import InfoCard from "../components/InfoCard.vue";
import IdeaSubmittedInfo from "../components/IdeaSubmittedInfo.vue";
import IdeaListCard from "../components/IdeaListCard.vue";
import IdeaGridCard from "../components/IdeaGridCard.vue";
import IdeaGridPlaceholder from "../components/IdeaGridPlaceholder.vue";
import LoaderView from "../components/progressView/Activities/LoaderView.vue";
import Toaster from "../components/Toaster.vue";

export default {
  components: {
    pagination,
    IdeaSubmittedInfo,
    IdeaListCard,
    IdeaGridCard,
    NoIdeaPage,
    LoaderView,
    Toaster,
    IdeaGridPlaceholder,
    InfoCard
  },
  data() {
    return {
      dataLoading: false,
      value: "",
      value2: "",
      open: false,
      showType: "",
      openInfoModal: false,
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "playbook",
      "initiatives",
      "loadingFlag",
      "pagination",
      "autoInitiativeInAction",
      "selectedInitiative",
      "ideaProcessStatus",
    ]),
    participantInitiatives() {
      let filteredResult = this.initiatives.filter((f, e) => {
        return [
          ...f?.metaData?.teamLead,
          ...f?.metaData?.teamMembers,
          ...f?.metaData?.mentors,
        ].some(
          (item) =>
            item.email?.toLowerCase() === this.user?.email?.toLowerCase()
        );
      });

      return filteredResult;
    },
  },
  created: function () {
    this.$store.dispatch("getMyInitiatives", { currentPage: 1 });
  },
  methods: {
    submittedInfoModalflag(open) {
      this.openInfoModal = open;
    },
    postNewIdea: function () {
      this.$router.push({ name: "NewIdea" });
    },
    // cardClick: function (idea) {
    //   alert("Card Click");
    //   const result = this.initiatives.filter(
    //     (initiative) => initiative.id == idea.id
    //   );
    //   const selectedIdea = result[0];
    //   this.$store.dispatch("setSelectedInitiative", selectedIdea);
    //   if (selectedIdea.status == -1) {
    //     this.$router.push({ name: "EditIdea" });
    //   } else {
    //     this.$router.push({ name: "MyIdeaDetails" });
    //   }

    //   window.scrollTo(0, 0);
    // },

    selectIdea: function (ideaId, idea) {
      this.$store.dispatch("setSelectedInitiative", idea);
      if (idea.status == -1) {
        this.$router.push({ name: "EditIdea" });
      } else {
        this.$router.push({ name: "MyIdeaDetails", params: { id: ideaId } });
        window.scrollTo(0, 0);
      }
    },

    cardClick: function (item) {
      if (item.status === 0) {
        this.submittedInfoModalflag(true);
      } else {
        this.selectIdea(item.id, item);
      }
    },

    pageChanged(page) {
      this.$store.dispatch("getMyInitiatives", { currentPage: page });
    },
    showTypeOver: function (item) {
      this.showType = item;
    },
    showTypeOut: function (item) {
      this.showType = null;
    },
  },
};
</script>
<style scoped>
.kanban-list {
  overflow: auto;
  max-height: calc(100vh - 12rem);
  min-height: calc(100vh - 12rem);
}
.hide-scroll::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
.phase-title {
  font-size: 1.25rem;
}
.dropdown:focus-within .dropdown-menu {
  opacity: 1;
  transform: translate(0) scale(1);
  visibility: visible;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@-webkit-keyframes loading {
  25% {
    fill: #c6c7ca;
  }
  50% {
    fill: #049fd9;
  }
  75% {
    fill: #c6c7ca;
  }
}
@keyframes loading {
  25% {
    fill: #c6c7ca;
  }
  50% {
    fill: #049fd9;
  }
  75% {
    fill: #c6c7ca;
  }
}
.logo {
  width: 180px;
  fill: #c6c7ca;
}

.logo__mark > path {
  animation: loading 1.25s ease-in-out infinite reverse;
}

.logo__mark > path:nth-child(1) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.logo__mark > path:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.logo__mark > path:nth-child(3) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.logo__mark > path:nth-child(4) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.logo__mark > path:nth-child(5) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.logo__mark > path:nth-child(6) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.logo__mark > path:nth-child(7) {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.logo__mark > path:nth-child(8) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.logo__mark > path:nth-child(9) {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
.multiselect-clear {
  display: none;
}
</style>








